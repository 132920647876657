import React from 'react';
// Libraries
import { Form, Input, Button, Row, Col, Select } from 'antd';
// Components
import Upload from './components/Upload';
import Tags from './components/Tags';

const { Option } = Select;

export default function Information() {
  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      layout="vertical"
      size="default"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{
        type: 'Governamental',
      }}
    >
      <Row style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Col span={4}>
          <Upload />
        </Col>
        <Col span={10}>
          <Form.Item
            name={['name']}
            label="Nome do Programa"
            style={{ marginRight: 10 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name={['type']}
            label="Tipo de Programa"
            style={{ marginRight: 10 }}
          >
            <Select>
              <Option value="Governamental">Governamental</Option>
              <Option value="Type 2">Type 2</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            name={['abrangencia']}
            label="Abrangência Territorial "
            style={{ marginRight: 10 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['visibilidade']} label="Visibilidade do Programa">
            <Select>
              <Option value="Público">Público</Option>
              <Option value="Privado">Privado</Option>
              <Option value="Protegido">Protegido</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item name="description" label="Descrição">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item name="description" label="Descrição">
            <Tags />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Editar
        </Button>
      </Form.Item>
    </Form>
  );
}

const text = {
  label: 'Texto',
  type: 'text',
  options: [
    {
      label: 'Está vazio',
      value: 'Está vazio',
    },
    {
      label: 'Não está vazio',
      value: 'Não está vazio',
    },
    {
      label: 'O texto contém',
      value: 'O texto contém',
    },
    {
      label: 'O texto não contém',
      value: 'O texto não contém',
    },
    {
      label: 'O texto começa com',
      value: 'O texto começa com',
    },
    {
      label: 'O texto termina com',
      value: 'O texto termina com',
    },
    {
      label: 'O texto é exatamente',
      value: 'O texto é exatamente',
    },
  ],
};

const data = {
  label: 'Data',
  type: 'data',
  options: [
    {
      label: 'A data é',
      value: 'A data é',
    },
    {
      label: 'Data anterior a',
      value: 'Data anterior a',
    },
    {
      label: 'Data posterior a',
      value: 'Data posterior a',
    },
  ],
};

const number = {
  label: 'Número',
  type: 'number',
  options: [
    {
      label: 'Maior que',
      value: 'Maior que',
    },
    {
      label: 'Maior ou igual a',
      value: 'Maior ou igual a',
    },
    {
      label: 'Menor que',
      value: 'Menor que',
    },
    {
      label: 'Menor ou igual a',
      value: 'Menor ou igual a',
    },
    {
      label: 'É igual a',
      value: 'É igual a',
    },
    {
      label: 'É diferente de',
      value: 'É diferente de',
    },
    {
      label: 'Está entre',
      value: 'Está entre',
    },
    {
      label: 'Não está entre',
      value: 'Não está entre',
    },
  ],
};

export { text, data, number };

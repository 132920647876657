import React, { useContext, useState } from 'react';

import { Form } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';

import Absence from './components/Absence';
import Custom from './components/Custom';
import Presence from './components/Presence';
import Types from './components/Types';
import BasicForm from './components/BasicForm';

export default function TriggerForm({ propertys }) {
  const { activitys } = propertys;

  const [triggerType, setTriggerType] = useState();

  const { theme } = useContext(ThemeContext);

  function CreateTrigger() {}

  return (
    <Form layout="vertical" onFinish={CreateTrigger}>
      <Types
        triggerType={triggerType}
        setTriggerType={setTriggerType}
        theme={theme}
      />
      {triggerType && (
        <>
          <BasicForm />
          {triggerType === 'absence' && (
            <Absence activitys={activitys} theme={theme} />
          )}
          {triggerType === 'custom' && (
            <Custom activitys={activitys} theme={theme} />
          )}
          {triggerType === 'presence' && (
            <Presence activitys={activitys} theme={theme} />
          )}
       
        </>
      )}
    </Form>
  );
}

import React, { useContext } from 'react';

import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Header from './components/Header';
import ColaboratorsTable from './components/AlertsTable';

import { Container } from './style';

export default function AddColaborators() {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Container>
      <Header isMobile={isMobile} />
      <ColaboratorsTable isMobile={isMobile} />
    </Container>
  );
}

import React, { memo } from 'react';

import { List } from 'antd'
import {  EditFilled, PlusOutlined } from '@ant-design/icons';

import Button from '~/components/button';
import Text from '~/components/typography/text';
import Drawer from '~/components/drawer';


import TriggerForm from './components/TriggerForm';

import { ButtonContainer } from './style' 

function Triggers({ triggers, setTriggers, activitys }) {
  console.log(`triggers`, triggers);


  function SetTriggerText(trigger) {
    switch (trigger.type) {
      case 'absence':
        return `Ausência: ${trigger.name}`;
      case 'custom':
        return `Customizado: ${trigger.name}`;
      case 'presence':
        return `Presença: ${trigger.name}`;
      default:
        break;
    }
  }


  return (
    <>
      <ButtonContainer>
          <Text strong>Gatilhos</Text>
          <Drawer
            placement="right"
            title="Criar gatilho para alerta"
            Trigger={
              <Button icon={<PlusOutlined />} disabled={activitys.length === 0}>
                Adicionar gatilho
              </Button>
            }
            footer
            footerMainButton={
              <Button type="primary" >
                Criar gatilho
              </Button>
            }
            DrawerContentComponent={TriggerForm}
            DrawerContentComponentParams={{ activitys, setTriggers }}
          />
      </ButtonContainer>
     
      {triggers.length > 0 ? (
         <List    
            style={{ marginBottom: 10 }}
            dataSource={triggers}
            renderItem={item => (
              <List.Item>
                <Text>{SetTriggerText(item)}</Text>
                <Drawer
                    placement="right"
                    title="Criar gatilho para alerta"
                    Trigger={
                      <Button icon={<EditFilled />}>Editar</Button>
                    }
                    DrawerContentComponent={TriggerForm}
                    DrawerContentComponentParams={{ activitys, setTriggers }}
                  />
              </List.Item>
            )}
          />
            
          
      ) : (
        <div />
      )}
    </>
  );
}

export default memo(Triggers);

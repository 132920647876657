import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Pages
import MyIncomingRules from './pages/MyIncomingRules';
import AddIncomingRules from './pages/AddIncomingRules';

export default function IncomingRules() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={`${path}`} component={MyIncomingRules} />
        <Route path={`${path}/nova-regra`} component={AddIncomingRules} />
      </Switch>
    </div>
  );
}

import React, { memo } from 'react';

import { MainOption, MainSelect, MainOptionGroup } from './style';

function AntSelectGroup({ groups, defaultValue, value, style, onChange }) {
  return (
    <MainSelect
      defaultValue={defaultValue || 'Regras'}
      value={value}
      style={style}
      onChange={onChange}
    >
      {groups &&
        groups.map((group) => (
          <MainOptionGroup key={group.label} label={group.label}>
            {group.options.map((item) => (
              <MainOption key={group.value} value={item.value}>
                {item.label}
              </MainOption>
            ))}
          </MainOptionGroup>
        ))}
    </MainSelect>
  );
}

export default memo(AntSelectGroup);

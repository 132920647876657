import React, { useContext } from 'react';
// Libraries
import { Divider,  Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Table from './components/Table';
import Button from '~/components/button';
import Card from '~/components/card';
import Title from '~/components/typography/title';

import { HeaderContainer } from './style'

const { Search } = Input;

export default function Benefits() {

  const { theme } = useContext(ThemeContext);

  return (
    
      <Card>
        <HeaderContainer>
         
          <Title level={4}>Benefícios</Title>
     
          <div>
            <Search
              placeholder="Pesquisar benefício"
              onSearch={(value) => console.log(value)}
              style={{ width: 200, marginRight: 10 }}
            />
            <Button
              text="Novo benefício"
              icon={<PlusOutlined />}
              type="primary"
            />
          </div>
        </HeaderContainer>
        <Divider style={{ borderColor: theme.color.white}} />
        <Table />
      </Card>
    
  );
}

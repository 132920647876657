import React, { memo, useState, useContext } from 'react';

import { SelectGroup } from '~/components/select';

import { ThemeContext } from '~/contexts/ThemeContext';

import Data from './components/Data';
import Number from './components/Number';
import Text from './components/Text';

import AntText from '~/components/typography/text';

import { Container, FlexRow, Div, CloseIcon } from './style';

import { data, number, text } from '../types';

function CustomInput({ customText, rules, setRules, enabledTypes }) {
  const [selectedType, setSelectedType] = useState('');
  const [selectedRule, setSelectedRule] = useState('');

  const { theme } = useContext(ThemeContext);

  function SelectEnableTypes() {
    const types = [text, data, number];

    if (enabledTypes) {
      return types.filter((item) => {
        return enabledTypes[item.type] === true;
      });
    }

    return types;
  }

  function GetSelectedTypeByRule(rule) {
    const types = [text, data, number];

    const filterType = types.filter((type) => {
      const filter = type.options.filter((item) => item.value === rule);

      return filter.length > 0;
    });

    setSelectedRule(rule);
    setSelectedType(filterType[0].type);
  }

  function RemoveRule() {}

  const InputStyle = {
    width: 180,
    margin: '5px 0',
  };

  return (
    <Container>
      <FlexRow>
        <Div>
          <AntText strong>{customText}</AntText>
          <CloseIcon onClick={() => RemoveRule()} color={theme.color.primary} />
        </Div>
        <SelectGroup
          style={InputStyle}
          onChange={(selectedValue) => GetSelectedTypeByRule(selectedValue)}
          groups={SelectEnableTypes()}
        />
        {selectedType === 'data' && (
          <Data InputStyle={InputStyle} rules={rules} setRules={setRules} />
        )}
        {selectedType === 'text' && (
          <Text
            selectedRule={selectedRule}
            InputStyle={InputStyle}
            rules={rules}
            setRules={setRules}
          />
        )}
        {selectedType === 'number' && (
          <Number
            InputStyle={InputStyle}
            rules={rules}
            selectedRule={selectedRule}
            setRules={setRules}
          />
        )}
      </FlexRow>
    </Container>
  );
}

export default memo(CustomInput);

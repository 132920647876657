import React, { memo, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';

import { cpfMaskContinuos, cnpjMask } from '~/utils/Masks';

import Avatar from '~/components/avatar';
import Title from '~/components/typography/title';
import Input from '~/components/input';
import Button from '~/components/button';
import CheckboxGroup from '~/components/checkboxGroup';

import { ButtonContainer, FlexBox } from './style';

function InviteColaboratorForm() {
  const [value, setValue] = useState('');

  const CreaeCPForCNPJMask = () => (event) => {
    if (event.target.value.length > 18) {
      return;
    }
    if (event.target.value.length <= 14) {
      return setValue(cpfMaskContinuos(event.target.value));
    } else if (event.target.value.length > 14) {
      return setValue(cnpjMask(event.target.value));
    }
  };

  function onChangeGetCheckedValues(checkedValues) {
    console.log('checkedValues: ', checkedValues);
  }

  const options = [
    {
      value: 'activitys',
      label: 'Visualizar atividades do programa',
    },
    {
      value: 'alerts',
      label: 'Visualizar beneficiários com atividades em alertas',
    },
    {
      value: 'benefits',
      label: 'Visualizar os benefícios do pograma',
    },
    {
      value: 'Informações',
      label: 'Visualizar e editar as informações do programa',
    },
    {
      value: 'link_beneficiary',
      label: 'Vincular um beneficiário ao programa',
    },
    {
      value: 'requests',
      label: 'Julgar solicitações de vínculo ao programa',
    },

    {
      value: 'rules',
      label: 'Visualizar e editar as regras do programa',
    },

    {
      value: 'add_colaborator',
      label: 'Adicionar colaboradores',
    },
    {
      value: 'comunication_center',
      label: 'Gerenciar a central de comunicações',
    },
    {
      value: 'manager_alerts',
      label: 'Criar e gerenciar alertas no programa',
    },
  ];

  return (
    <>
      <FlexBox>
        <Avatar
          src="https://uifaces.co/our-content/donated/1H_7AxP0.jpg"
          size={135}
        />
        <Title level={4}>Roberto da Silva</Title>
        <Button>Adicionar</Button>
      </FlexBox>

      <CheckboxGroup onChange={onChangeGetCheckedValues} options={options} />

      <Input
        margin="20px 0px 10px"
        placeholder="Digite seu CPF"
        prefix={<UserOutlined />}
        onChange={CreaeCPForCNPJMask()}
        value={value}
        size="large"
      />

      <ButtonContainer>
        <Button type="primary">Buscar</Button>
      </ButtonContainer>
    </>
  );
}

export default memo(InviteColaboratorForm);

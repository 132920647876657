import React from 'react';
// Libraries
import { List, Avatar } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
// Services
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

const data = [
  {
    name: 'Isadora de Souza Andrade',
    avatar:
      'https://www.rd.com/wp-content/uploads/2017/09/01-shutterstock_476340928-Irina-Bg-1024x683.jpg',
    link: 'Solicitação realizada há 18 dias',
  },
];

export default function PendingList() {
  // Render
  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button text="Recusar" icon={<CloseOutlined />} />,
            <Button text="Aceitar" type="primary" icon={<CheckOutlined />} />,
          ]}
        >
          <List.Item.Meta
            avatar={
              <Avatar src={item.avatar ? item.avatar : GetDefaultAvatar("male")} />
            }
            title={item.name}
            description={`${item.link}`}
          />
        </List.Item>
      )}
    />
  );
}

import React from 'react';

import { PlusOutlined } from '@ant-design/icons';

import Title from '~/components/typography/title';
import Search from '~/components/search';
import Button from '~/components/button';

import { Container, Div } from './style';

export default function Benefits() {
  return (
    <Container>
      <Title level={4} style={{ margin: 0 }}>
        Benefícios
      </Title>
      <Div>
        <Search
          placeholder="Pesquisar benefício"
          onSearch={(value) => console.log(value)}
          style={{ width: 200, marginRight: 10 }}
        />
        <Button text="Novo benefício" icon={<PlusOutlined />} type="primary" />
      </Div>
    </Container>
  );
}

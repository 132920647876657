import React from 'react';
// Contexts
// Libraries
import { Tag, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { PrimaryTextCell } from '~/components/table/cells';
import Table from '~/components/table';

import { ButtonContainer } from './style'

export default function ActivityTable() {

  const columns = [
    {
      title: 'Atividade',
      dataIndex: 'name',
      key: 'name',
      render: (name) => (
        <PrimaryTextCell text={name} />
      ),
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (tags) => (
        <span>
          {tags.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'dentes') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: 'Local',
      dataIndex: 'address',
      key: 'address',
      render: (address) => (
        <PrimaryTextCell text={address} />
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      align: "center",
      render: () => (
        <ButtonContainer>
          <Button type="dashed" style={{ marginRight: 5 }}>
            Editar
          </Button>
          <Button icon={<DeleteOutlined />} />
        </ButtonContainer>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      name: 'Consulta de Pré-natal',
      address: 'UBS Aeroporto',
      date: '25/04/2020',
      tags: ['saúde', 'consultas'],
    },
    {
      key: '2',
      name: 'Consulta no Dentista',
      address: 'SESI',
      date: '20/04/2020',
      tags: ['dentes'],
    },
    {
      key: '3',
      name: 'Consulta de Pré-natal',
      address: 'UBS Aeroporto',
      date: '16/04/2020',
      tags: ['saúde', 'consultas'],
    },
  ];
  return <Table pagination={false} tableColumns={columns} dataReceived={data} />;
}

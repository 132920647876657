import React, { memo } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import InviteMember from './components/InviteColaborator';

import Title from '~/components/typography/title';
import Button from '~/components/button';
import Drawer from '~/components/drawer';

import { Container } from './style';

function Header() {
  return (
    <Container>
      <Title level={4} style={{ margin: 0 }}>
        Central de comunicações
      </Title>
      <Drawer
        placement="right"
        title="Adicionar novo colaborador"
        Trigger={
          <Button type="primary" icon={<PlusOutlined />}>
            Novo membro
          </Button>
        }
        DrawerContentComponent={InviteMember}
      />
    </Container>
  );
}

export default memo(Header);

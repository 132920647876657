import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Pages
import MyMaintenanceRules from './pages/MyMaintenanceRules';
import AddMaintenanceRules from './pages/AddMaintenanceRules';

export default function MaintenanceRules() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={`${path}`} component={MyMaintenanceRules} />
        <Route path={`${path}/nova-regra`} component={AddMaintenanceRules} />
      </Switch>
    </div>
  );
}

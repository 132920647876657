import React from 'react';
// Libraries
import { Typography, Divider, Row, Col } from 'antd';

import Card from '~/components/card';
import Graph1 from './components/Graph1';
import Graph2 from './components/Graph2';
import Graph3 from './components/Graph3';

const { Title } = Typography;


export default function Moves() {
  // Variables
  return (
    <div>
     
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title level={4}>Minhas movimentações</Title>
        </div>
        <Divider />
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Graph1 />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Graph2 />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} />
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Graph3 />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

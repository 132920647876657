import React, { useEffect, useState, useContext } from 'react';
// Libraries
import { Avatar, Divider, Form, Typography } from 'antd';
// Components
import { SimpleSelect } from '~/components/form/Questions';
// Services
import { GetDefaultAvatar } from '~/utils/SocialMeAssets';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
import { AuthContext } from '~/contexts/AuthContext';

const { Text } = Typography;

export default function Fill({ people, form }) {
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  const { user } = useContext(AuthContext);
  // Variables
  const [kinships, setKinships] = useState([]);

  useEffect(() => {
    async function GetKinships() {
      const params = {
        operation: ['Profile', 'Relantionship', 'Kinships'],
        urlParams: {
          personId: user._id,
        },
      };
      console.log(params);
      const response = await CallToTheAPI(params);
      console.log(response);
      if (
        response &&
        response.body &&
        response.body.kinship &&
        response.body.kinship.elements
      ) {
        const array = [];
        for (let i = 0; i < response.body.kinship.elements.length; i++) {
          const newItem = {
            value: response.body.kinship.elements[i]._id,
            label: response.body.kinship.elements[i].neutral,
          };
          array.push(newItem);
        }
        console.log(array);
        return setKinships(array);
      }
    }
    GetKinships();
  }, [CallToTheAPI, user]);

  const LinkQuestion = () => {
    if (kinships.length > 19) {
      const data = {
        nameItem: 'link',
        placeholder: 'Selecione',
        width: '200px',
        options: kinships,
      };
      return SimpleSelect(data);
    }
    const data = {
      nameItem: 'link',
      placeholder: 'Selecione',
      width: '200px',
      options: kinships,
    };
    return SimpleSelect(data);
  };

  return (
    <div>
      <Divider>Tipo de relacionamento</Divider>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        scrollToFirstError
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Avatar
            size={64}
            src={people.avatar ? people.avatar : GetDefaultAvatar("male")}
          />
          <Text strong>{people.name}</Text>

          <LinkQuestion />
        </div>
      </Form>
    </div>
  );
}

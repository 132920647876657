import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Pages
import AddActivity from './pages/AddActivity';
import MyActivities from './pages/MyActivities';

export default function Relationships() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={MyActivities} />
      <Route path={`${path}/nova-atividade`} component={AddActivity} />
    </Switch>
  );
}

import React from 'react';
// Libraries
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import Acitivitys from './pages/Activitys';
import Alerts from './pages/Alerts';
import Benefits from './pages/Benefits';
import Information from './pages/Information';
import LinkBeneficiary from './pages/LinkBeneficiary';
import Overview from './pages/Overview';
import Requests from './pages/Requests';
import Rules from './pages/Rules';
import ComunicationCenter from './pages/ComunicationCenter';
import AddColaborators from './pages/AddColaborators';
import ManageAlerts from './pages/ManageAlerts';

// Components
import Card from '~/components/card';

export default function Content() {
  const { url } = useRouteMatch();

  const cardStyle = {
    borderRadius: 0,
    height: 'calc(100vh - 126px)',
    overflowY: 'auto',
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  };

  return (
    <Card bordered={false} style={cardStyle}>
      <Switch>
        <Route path={`${url}/atividades`} component={Acitivitys} />
        <Route path={`${url}/alerta`} component={Alerts} />
        <Route path={`${url}/beneficios`} component={Benefits} />
        <Route path={`${url}/informacao`} component={Information} />
        <Route
          path={`${url}/vincular-beneficiario`}
          component={LinkBeneficiary}
        />
        <Route path={`${url}/visao-geral`} component={Overview} />
        <Route path={`${url}/requisicoes`} component={Requests} />
        <Route path={`${url}/regras`} component={Rules} />
        <Route
          path={`${url}/central-de-comunicacoes`}
          component={ComunicationCenter}
        />
        <Route
          path={`${url}/adicionar-coloborador`}
          component={AddColaborators}
        />
        <Route path={`${url}/gerenciar-alertas`} component={ManageAlerts} />
        <Route exact path="*">
          <Redirect to={`${url}/atividades`} />
        </Route>
      </Switch>
    </Card>
  );
}

import React, { memo, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';

import { cpfMaskContinuos, cnpjMask } from '~/utils/Masks';

import Avatar from '~/components/avatar';
import Title from '~/components/typography/title';
import Input from '~/components/input';
import Button from '~/components/button';

import { ButtonContainer, FlexBox } from './style';

function InviteColaboratorForm() {
  const [value, setValue] = useState('');

  const CreaeCPForCNPJMask = () => (event) => {
    if (event.target.value.length > 18) {
      return;
    }
    if (event.target.value.length <= 14) {
      return setValue(cpfMaskContinuos(event.target.value));
    } else if (event.target.value.length > 14) {
      return setValue(cnpjMask(event.target.value));
    }
  };

  return (
    <>
      <FlexBox>
        <Avatar
          src="https://images-na.ssl-images-amazon.com/images/M/MV5BMTQwMDQ0NDk1OV5BMl5BanBnXkFtZTcwNDcxOTExNg@@._V1_UY256_CR2,0,172,256_AL_.jpg"
          size={135}
        />
        <Title level={4}>Letícia Menegel</Title>
        <Button>Adicionar</Button>
      </FlexBox>

      <Input
        margin="20px 0px 10px"
        placeholder="Digite seu CPF"
        prefix={<UserOutlined />}
        onChange={CreaeCPForCNPJMask()}
        value={value}
        size="large"
      />

      <ButtonContainer>
        <Button type="primary">Buscar</Button>
      </ButtonContainer>
    </>
  );
}

export default memo(InviteColaboratorForm);

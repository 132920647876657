import React, { memo, useState } from 'react';

import { Select } from '~/components/select';
import FormItem from '~/components/form/FormItem';
import Button from '~/components/button';
import Divider from '~/components/divider';
import CustomInput from '~/components/customInput';

import { FlexRow, Div, AntRow, AntCol } from './style';

const fakeCustomData = [
  {
    value: 'Dia realizado',
    label: 'Dia realizado',
  },
  {
    value: 'Dia para realizar o teste de covid',
    label: 'Dia para realizar o teste de covid',
  },
  {
    value: 'Resultado',
    label: 'Resultado',
  },
];

function Custom({ theme, activitys }) {
  const [customData, setCustomData] = useState(fakeCustomData[0].value);
  const [rules, setRules] = useState([]);

  return (
    <>
      <FormItem
        label="Atividade selecionada"
        name="acivitys"
        item={<Select options={activitys} />}
      />
      <FormItem
        label="Campo customizado"
        name="rules"
        item={
          <FlexRow>
            <Div>
              <Select
                onChange={(value) => setCustomData(value)}
                options={fakeCustomData}
              />
            </Div>

            <Button
              onClick={() => {
                setRules([...rules, customData]);
                setCustomData('');
              }}
            >
              Vincular
            </Button>
          </FlexRow>
        }
      />
      <Divider />
      {rules.length > 0 ? (
        <AntRow>
          {rules.map((rule) => (
            <AntCol key={rule} span={24}>
              <CustomInput
                setRules={setRules}
                rules={rules}
                customText={rule}
              />
            </AntCol>
          ))}
        </AntRow>
      ) : (
        <div />
      )}
    </>
  );
}

export default memo(Custom);

import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Pages
import MyBenefitRules from './pages/MyBenefitRules';
import AddBenefitRules from './pages/AddBenefitRules';

export default function BenefitRules() {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={`${path}`} component={MyBenefitRules} />
        <Route path={`${path}/nova-regra`} component={AddBenefitRules} />
      </Switch>
    </div>
  );
}

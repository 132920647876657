import React from 'react';
// Libraries
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Typography, Button, Divider } from 'antd';


const { Title } = Typography;

export default function AddPrograms() {
  // Variables
  return (
    <div>
   
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title level={4}>Novo Programa</Title>
          <Link to="/programas">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Voltar
            </Button>
          </Link>
        </div>
        <Divider />
        <div
          style={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Title level={4} style={{ color: '#bdbdbd' }}>
            Planejar Tela
          </Title>
        </div>
      </Card>
    </div>
  );
}

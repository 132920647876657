import React from 'react';

import { Switch } from 'antd';

import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

export default function BasicForm() {
  return (
    <>
      <FormItem label="Nome" name="name" initialValue="" item={<Input />} />
      <FormItem
        name="active"
        label="Gatilho ativo"
        item={
          <Switch
            defaultChecked
            onChange={(checked) => console.log(`checked`, checked)}
          />
        }
      />
    </>
  );
}

import React, { memo, useState } from 'react';

import InputNumber from '~/components/inputNumber';

import { Container } from './style';

function Number({ InputStyle, selectedRule }) {
  const [number, setNumber] = useState();

  return (
    <Container>
      {selectedRule === 'Está entre' || selectedRule === 'Não está entre' ? (
        <>
          <div>
            <InputNumber
              defaultValue={0}
              value={number}
              onChange={(value) => setNumber({ ...number, min: value })}
              style={InputStyle}
            />
            e
          </div>

          <InputNumber
            defaultValue={0}
            value={number}
            onChange={(value) => setNumber({ ...number, max: value })}
            style={InputStyle}
          />
        </>
      ) : (
        <InputNumber
          defaultValue={0}
          value={number}
          onChange={(value) => setNumber(value)}
          style={InputStyle}
        />
      )}
    </Container>
  );
}

export default memo(Number);

import React from 'react';
import Chart from 'react-apexcharts';
import './Graph2.css';

const options = {
  stroke: {
    width: 0,
  },
  plotOptions: {
    heatmap: {
      enableShades: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: '#008FFB',
          },
          {
            from: 1,
            to: 2,
            color: '#00E396',
          },
          {
            from: 3,
            to: 4,
            color: '#6f42c1',
          },
          {
            from: 5,
            to: 6,
            color: '#ffc107',
          },
        ],
      },
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: ['#fff'],
    },
  },
  xaxis: {
    type: 'category',
  },
};

/*
function generateData(count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = (i + 1).toString();
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push({
      x: x,
      y: y
    });
    i++;
  }
  return series;
}
*/

const series = [
  {
    name: 'Programa 1',
    data: [
      {
        x: 'Programa 1',
        y: 0,
      },
      {
        x: 'Programa 2',
        y: 2,
      },
      {
        x: 'Programa 3',
        y: 4,
      },
      {
        x: 'Programa 4',
        y: 3,
      },
      {
        x: 'Programa 5',
        y: 1,
      },
    ],
  },
  {
    name: 'Programa 2',
    data: [
      {
        x: 'Programa 1',
        y: 2,
      },
      {
        x: 'Programa 2',
        y: 0,
      },
      {
        x: 'Programa 3',
        y: 1,
      },
      {
        x: 'Programa 4',
        y: 2,
      },
      {
        x: 'Programa 5',
        y: 1,
      },
    ],
    /*
    data: generateData(5, {
      min: 4,
      max: 8
    })
    */
  },
  {
    name: 'Programa 3',
    data: [
      {
        x: 'Programa 1',
        y: 3,
      },
      {
        x: 'Programa 2',
        y: 2,
      },
      {
        x: 'Programa 3',
        y: 0,
      },
      {
        x: 'Programa 4',
        y: 6,
      },
      {
        x: 'Programa 5',
        y: 2,
      },
    ],
  },
  {
    name: 'Programa 4',
    data: [
      {
        x: 'Programa 1',
        y: 4,
      },
      {
        x: 'Programa 2',
        y: 1,
      },
      {
        x: 'Programa 3',
        y: 6,
      },
      {
        x: 'Programa 4',
        y: 0,
      },
      {
        x: 'Programa 5',
        y: 2,
      },
    ],
  },
  {
    name: 'Programa 5',
    data: [
      {
        x: 'Programa 1',
        y: 1,
      },
      {
        x: 'Programa 2',
        y: 0,
      },
      {
        x: 'Programa 3',
        y: 1,
      },
      {
        x: 'Programa 4',
        y: 2,
      },
      {
        x: 'Programa 5',
        y: 0,
      },
    ],
  },
];

const Graph2 = () => {
  return (
    <Chart
      options={options}
      series={series}
      type="heatmap"
      height="300"
      width="80%"
    />
  );
};

export default Graph2;

import React, { memo } from 'react';

import { Tag } from 'antd';

import Popover from '~/components/pophover';

import PopupDiv from './style';

function TagsCell({ theme, popupTags }) {
  return popupTags.length > 0 ? (
    <Popover
      title="Mais tags"
      style={{ display: popupTags.length > 0 ? 'block' : 'none' }}
      content={
        <PopupDiv>
          {popupTags.map((tag) => {
            return (
              <Tag key={tag.text} id={tag.color} color={tag.color}>
                {tag.text}
              </Tag>
            );
          })}
        </PopupDiv>
      }
    >
      <Tag color={theme.color.primary}>{`+ ${popupTags.length}`}</Tag>
    </Popover>
  ) : (
    <div />
  );
}

export default memo(TagsCell);

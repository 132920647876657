import React from 'react';
// Libraries
import { Form, Input, Button, Select } from 'antd';

const { Option } = Select;

export default function Information() {
  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      layout="vertical"
      size="default"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{
        type: 'Governamental',
      }}
    >
      <Form.Item label="Inatividade">
        <Form.Item name="inativa_exatamente">
          <Input
            addonBefore="Não executou nenhum atividade há"
            addonAfter="dias"
            style={{ width: 350 }}
          />
        </Form.Item>
        <Form.Item name="inativa_nao_executou">
          <Input
            addonBefore="Não executou mais de"
            addonAfter="atividades"
            style={{ width: 350 }}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Frequência">
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <p style={{ margin: '0px 5px 0px 0px' }}>Não execução da atividade</p>
          <Form.Item name="frequencia_1">
            <Select style={{ width: 200 }}>
              <Option value="Atividade 1">Atividade 1</Option>
              <Option value="Atividade 2">Atividade 2</Option>
              <Option value="Atividade 3">Atividade 3</Option>
            </Select>
          </Form.Item>
          <p style={{ margin: '0px 5px 0px 5px' }}>de forma</p>
          <Form.Item name="frequencia_2">
            <Select style={{ width: 200 }}>
              <Option value="Semanal">Semanal</Option>
              <Option value="Quinzenal">Quinzenal</Option>
              <Option value="Mensal">Mensal</Option>
            </Select>
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item label="Temporaridade">
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <p style={{ margin: '0px 5px 0px 0px' }}>Não execução da atividade</p>
          <Form.Item name="temporidade_1">
            <Select style={{ width: 200 }}>
              <Option value="Atividade 1">Atividade 1</Option>
              <Option value="Atividade 2">Atividade 2</Option>
              <Option value="Atividade 3">Atividade 3</Option>
            </Select>
          </Form.Item>
          <p style={{ margin: '0px 5px 0px 5px' }}>no mês</p>
          <Form.Item name="temporidade_2">
            <Select style={{ width: 100 }}>
              <Option value="Mês 1">Mês 1</Option>
              <Option value="Mês 2">Mês 2</Option>
              <Option value="Mês 3">Mês 3</Option>
            </Select>
          </Form.Item>
          <p style={{ margin: '0px 5px 0px 5px' }}>no ano</p>
          <Form.Item name="temporidade_3">
            <Select style={{ width: 100 }}>
              <Option value="Ano 1">Ano 1</Option>
              <Option value="Ano 2">Ano 2</Option>
              <Option value="Ano 3">Ano 3</Option>
            </Select>
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Adicionar regra
        </Button>
      </Form.Item>
    </Form>
  );
}

import React from 'react';
// Components
import Tabs from '~/components/tabs';
// Tabs
import IncomingRules from './tabs/IncomingRules';
import MaintenanceRules from './tabs/MaintenanceRules';
import BenefitRules from './tabs/BenefitRules';

export default function Rules() {
  // Variables
  const tabsItems = [
    {
      title: 'Entrada',
      component: IncomingRules,
      tabPath: '/regras-de-entrada',
    },
    {
      title: 'Manutenção',
      component: MaintenanceRules,
      tabPath: '/regras-de-manutencao',
    },
    {
      title: 'Benefícios',
      component: BenefitRules,
      tabPath: '/regras-de-beneficios',
    },
  ];
  const tabRedirect = {
    to: '/regras-de-entrada',
  };
  // Render
  return (
    <div>
      <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} />
    </div>
  );
}

import styled from 'styled-components';

import { Mentions } from 'antd';

const { Option } = Mentions;

export const MainMentions = styled(Mentions)`
  border-radius: 4px;

  .rc-textarea {
    border-radius: 8px;
    height: ${(props) => props.style.height};
  }
`;

export const MainOptions = styled(Option)``;

import React from 'react';

import { EditFilled, DeleteOutlined } from '@ant-design/icons';

import CreateOrEditAlerts from '../CreateOrEditAlerts';

import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Table from '~/components/table';
import { PrimaryTextCell, TagsCell } from '~/components/table/cells';

import { Flex } from './style';

export default function AlertsTable({ isMobile }) {
  const columns = [
    {
      title: 'Nome',
      dataIndex: 'alert',
      key: 'alert',
      width: '50%',
      render: (alert) => <PrimaryTextCell text={alert} />,
    },

    {
      title: 'Atividades',
      dataIndex: 'tags',
      key: 'tags',
      width: '25%',
      render: (tags, record) => (
        <TagsCell id={record.alert} tags={tags} maxWidth="200px" />
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      width: '25%',
      render: () => (
        <Flex>
          <Drawer
            placement="right"
            title="Criar alerta"
            width={isMobile ? '100%' : '40%'}
            Trigger={<Button icon={<EditFilled />}>Editar</Button>}
            DrawerContentComponent={CreateOrEditAlerts}
          />
          <Button style={{ marginLeft: 5 }} icon={<DeleteOutlined />} />
        </Flex>
      ),
    },
  ];

  const data = [
    {
      id: 1,
      alert: 'Criar cateira',
      tags: [
        {
          text: 'Verificar regras governamentais',
          color: 'yellow',
        },
      ],
    },
    {
      id: 2,
      alert: 'Situação da criança',
      tags: [
        {
          text: 'Verificar idade',
          color: 'green',
        },
        {
          text: 'Situação econômica dos pais',
          color: 'yellow',
        },
        {
          text: 'Ver regras para benefícios',
          color: 'yellow',
        },
        {
          text: 'Verificar a saúde da criança',
          color: 'blue',
        },
        {
          text: 'Escolaridade da criança',
          color: 'red',
        },
      ],
    },
    {
      id: 3,
      alert: 'Teste de Covid-19',
      tags: [
        {
          text: 'Realizar exame',
          color: 'red',
        },
      ],
    },
    {
      id: 4,
      alert: 'Avisar sobre a rotina de exercícios de saúde',
      tags: [
        {
          text: 'Fazer exercícios',
          color: 'yellow',
        },
        {
          text: 'Exames médios anuais',
          color: 'yellow',
        },
      ],
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}

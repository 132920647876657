import React, { useState, useContext } from 'react';
// Libraries
import { Form } from 'antd';
// Contexts
import { AxiosContext } from '~/contexts/AxiosContext';
// Components
import Button from '~/components/button';
import Loading from '~/components/loading';
// Services
import { RemoveWhatIsNotNumber } from '~/utils/Transformations';

import { AmericanDateParser } from '~/utils/Parsers';

import SearchPerson from './steps/SearchPerson';
import Select from './steps/Select';
import Fill from './steps/Fill';

export default function LinkBeneficiary() {
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  // Variables
  const [load, setLoad] = useState(false);
  const [step, setStep] = useState('search');
  const [searchForm] = Form.useForm();
  const [linkForm] = Form.useForm();
  const [peoples, setPeoples] = useState();
  const [people, setPeople] = useState();
  // Functions
  async function Search(data) {
    setLoad(true);
    const params = {
      operation: ['Person', 'Search'],
      data,
    };
    console.log(params);
    const response = await CallToTheAPI(params);
    if (
      response &&
      response.body &&
      response.body.people &&
      response.body.people.elements
    ) {
      setPeoples(response.body.people.elements);
      setStep('select');
    }
    return setLoad(false);
  }
  const onFinishSearch = (data) => {
    return;
    if (data.filter.birth) {
      data.filter.birth = AmericanDateParser(data.filter.birth);
    }
    if (data.filter.cpf) {
      data.filter.cpf = RemoveWhatIsNotNumber(data.filter.cpf);
    }
    if (data.filter.cns) {
      data.filter.cns = RemoveWhatIsNotNumber(data.filter.cns);
    }
    if (data.filter.pis) {
      data.filter.pis = RemoveWhatIsNotNumber(data.filter.pis);
    }
    const dataTreated = {
      fieldsToMatch: 2,
      filter: data.filter,
    };
    Search(dataTreated);
  };
  async function onFinishFill() {
    console.log('Terminou');
  }
  const RenderButton = () => {
    switch (step) {
      case 'search':
        return (
          <Button
            text="Buscar"
            loading={load}
            disabled={load}
            type="primary"
            onClick={() => {
              searchForm.validateFields().then((values) => {
                onFinishSearch(values);
              });
            }}
          />
        );
      case 'select':
        return (
          <Button
            text="Pesquisar novamente"
            onClick={() => setStep('search')}
          />
        );
      case 'fill':
        return (
          <>
            <Button
              text="Voltar"
              style={{ marginRight: 8 }}
              onClick={() => setStep('select')}
            />
            <Button
              text="Adicionar"
              loading={load}
              disabled={load}
              type="primary"
              onClick={() => {
                linkForm.validateFields().then((values) => {
                  onFinishFill(values);
                });
              }}
            />
          </>
        );
      default:
        return null;
    }
  };
  const RenderStep = () => {
    switch (step) {
      case 'search':
        return <SearchPerson form={searchForm} />;
      case 'select':
        return (
          <Select setStep={setStep} peoples={peoples} setPeople={setPeople} />
        );
      case 'fill':
        return <Fill people={people} form={linkForm} />;
      default:
        return null;
    }
  };
  return (
    <>
      {load ? <Loading /> : RenderStep()}
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button text="Cancelar" style={{ marginRight: 8 }} />

        {RenderButton()}
      </div>
    </>
  );
}

import React, { memo } from 'react';

import Text from '~/components/typography/text';

import { MainMentions, MainOptions } from './style';

function AntMentions({ options, label, style, onChange }) {
  return (
    <>
      {label && (
        <Text strong style={{ marginBottom: 5 }}>
          {label}
        </Text>
      )}
      <MainMentions
        style={style}
        onChange={(values) => onChange(values, 'content')}
      >
        {options.map((option) => (
          <MainOptions key={option.value} value={option.value}>
            {option.label}
          </MainOptions>
        ))}
      </MainMentions>
    </>
  );
}

export default memo(AntMentions);

import styled from 'styled-components';

import { Input } from 'antd';

export const SearchInput = styled(Input.Search)`
  width: ${(props) => props.width || '100%'};
  margin: ${(props) => props.margin || '0px'};
  color: ${(props) => props.color};

  & button.ant-input-search-button {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

import React from 'react';
// Libraries
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// Pages
import MyPrograms from './pages/MyPrograms';
import AddProgram from './pages/AddProgram';
import ShowProgram from './pages/ShowProgram';

export default function Relationship() {
  // Variables
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={MyPrograms} />
      <Route path={`${path}/novo-programa`} component={AddProgram} />
      <Route path={`${path}/programa`} component={ShowProgram} />
    </Switch>
  );
}

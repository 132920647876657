import React from 'react';

import { EditFilled } from '@ant-design/icons';

import InviteColaborator from '../InviteColaborator';

import Drawer from '~/components/drawer';
import Button from '~/components/button';
import Table from '~/components/table';
import { ImageWithTextCell } from '~/components/table/cells';

export default function ColaboratorsTable() {
  // Variables
  const columns = [
    {
      title: 'Colaborador',
      dataIndex: 'colaborator',
      key: 'colaborator',
      width: '80%',
      render: (colaborator, record) => (
        <ImageWithTextCell src={record.avatar} text={colaborator} />
      ),
    },

    {
      title: 'Ações',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      render: () => (
        <Drawer
          placement="right"
          title="Adicionar novo colaborador"
          Trigger={<Button icon={<EditFilled />}>Editar</Button>}
          DrawerContentComponent={InviteColaborator}
        />
      ),
    },
  ];

  const data = [
    {
      id: '1',
      avatar: 'https://uifaces.co/our-content/donated/gPZwCbdS.jpg',
      colaborator: 'Matheus Ribeiro',
    },
    {
      id: '2',
      avatar: 'https://uifaces.co/our-content/donated/3799Ffxy.jpeg',
      colaborator: 'Sabrina Menezes',
    },
    {
      id: '3',
      avatar: 'https://randomuser.me/api/portraits/men/97.jpg',
      colaborator: 'Gêneses Matheus',
    },
    {
      id: '4',
      avatar: 'https://randomuser.me/api/portraits/men/35.jpg',
      colaborator: 'Rafael Solto',
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}

import React from 'react';
// Libraries
import { Statistic, Row, Col } from 'antd';

export default function Overview() {
  return (
    <div>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic title="Beneficiários no programa" value={202} />
        </Col>
        <Col span={8}>
          <Statistic title="Atividades Desenvolvidas" value="23.683" />
        </Col>
        <Col span={8}>
          <Statistic title="Investimentos no programa" value="R$ 11.983,00" />
        </Col>
      </Row>
    </div>
  );
}

import React from 'react';
// Libraries
import { DeleteOutlined } from '@ant-design/icons';
// Components
import { Table, Button } from 'antd';
import { BadgeCell } from '~/components/table/cells';

export default function TableActivity() {
  // Variables
  const columns = [
    {
      title: 'Benefício',
      dataIndex: 'benefit',
      key: 'benefit',
    },
    {
      title: 'Situação',
      dataIndex: 'situation',
      key: 'situation',
      render: (situation) => (
        <BadgeCell
          text={situation}
          status={situation === 'Em uso' ? 'success' : 'error'}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'situation',
      key: 'action',
      render: () => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button type="dashed" style={{ marginRight: 5 }}>
            Editar
          </Button>
          <Button icon={<DeleteOutlined />} />
        </div>
      ),
    },
  ];

  const data = [
    {
      key: '54',
      benefit: 'Regra da maior idade',
      quantidade: '57',
      value: 'R$ 4.440,00',
      situation: 'Em uso',
    },
    {
      key: '1',
      benefit: 'Regra 2',
      quantidade: '57',
      value: 'R$ 2.200,00',
      situation: 'Suspensa',
    },
    {
      key: '2',
      benefit: 'Regra 3',
      quantidade: '100',
      value: 'R$ 6.500,00',
      situation: 'Suspensa',
    },
    {
      key: '3',
      benefit: 'Regra 4',
      quantidade: '50',
      value: 'R$ 3.600,00',
      situation: 'Suspensa',
    },
  ];
  return <Table pagination={false} columns={columns} dataSource={data} />;
}

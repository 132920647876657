import React from 'react';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';

export default function Programs() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 20,
      }}
    >
      <div
        style={{
          width: '80%',
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <img
            src="https://socialme-assets.s3.us-east-1.amazonaws.com/images/imageTeste5.png"
            alt="..."
            style={{ display: 'block', margin: '0px auto' }}
          />
        </div>
        <div>
          <Title level={4} textAlign="center">
            Aliquam commodo lobortis imperdiet. Sed ac massa feugiat,
            pellentesque risus eu, egestas diam. Maecenas dignissim mi quis erat
            blandit, quis blandit leo dignissim. Nunc ornare ornare neque et
            sagittis. Maecenas mauris tellus, egestas vel tellus accumsan,
            ullamcorper laoreet justo. Aenean consectetur mollis est,
            condimentum dapibus risus ultrices eu. Fusce in nisl a arcu blandit
            mollis.
          </Title>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin: '20px auto',
            flexDirection: 'column',
            width: 200,
          }}
        >
          <a href="https://accounts.socialme.com.br">
            <Button
              type="primary"
              size="large"
              style={{ margin: '0px auto', display: 'block' }}
            >
              Entrar no Social Me
            </Button>
          </a>
          <a href="https://accounts.socialme.com.br">
            <Button type="link" style={{ marginTop: 10 }}>
              Criar uma conta Social Me
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

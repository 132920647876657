import React, { memo } from 'react';

import { Switch } from 'antd';

import CheckboxGroup from '~/components/checkboxGroup';
import FormItem from '~/components/form/FormItem';
import Mentions from '~/components/mentions';

import { Container, MessageSendTypeContainer, SwitchContainer } from './style';

function MessageSend({ messageConfig, onChange }) {
  const options = [
    {
      value: 'sms',
      label: 'SMS',
    },
    {
      value: 'telegram',
      label: 'Telegram',
    },
    {
      value: 'whatsapp',
      label: 'Whatsapp',
    },
  ];

  return (
    <Container>
      <Mentions
        label="Mensagem"
        style={{ width: '100%', height: '250px' }}
        onChange={(values) => onChange(values, 'content')}
        options={[
          {
            value: 'primeiro',
            label: 'primeiro',
          },
          {
            value: 'segundo',
            label: 'segundo',
          },
          {
            value: 'terceiro',
            label: 'terceiro',
          },
        ]}
      />

      <SwitchContainer>
        <FormItem
          name="internal"
          label="Mensagem interna"
          item={
            <Switch
              checked={messageConfig.internal}
              onChange={(checked) => {
                if (messageConfig.external === true) {
                  onChange(checked, 'internal');
                }
              }}
            />
          }
        />

        <FormItem
          name="external"
          label="Mensagem externa"
          item={
            <Switch
              checked={messageConfig.external}
              onChange={(checked) => {
                if (messageConfig.internal === true) {
                  onChange(checked, 'external');
                }
              }}
            />
          }
        />
      </SwitchContainer>
      {messageConfig.external && (
        <MessageSendTypeContainer>
          <CheckboxGroup
            defaultValue={messageConfig.externalMessageTypes}
            onChange={(values) => onChange(values, 'externalMessageTypes')}
            options={options}
          />
        </MessageSendTypeContainer>
      )}
    </Container>
  );
}

export default memo(MessageSend);

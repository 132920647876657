import React, { memo, useContext, useState } from 'react';

import { Form, Switch } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';
import FormItem from '~/components/form/FormItem';
import Input from '~/components/input';

import MessagerSend from './components/MessagerSend';
import Activitys from './components/Activitys';
import Triggers from './components/Triggers';

function CreateOrEditAlerts() {
  const { theme } = useContext(ThemeContext);

  const [selectedActivitys, setSelectedActivitys] = useState([]);
  const [triggers, setTriggers] = useState([
    {
      name: 'Teste covid',
      type: 'absence',
    },
    {
      name: 'Exames médicos anuais',
      type: 'absence',
    },
    {
      name: 'Dentista',
      type: 'custom',
    },
  ]);

  const [messageConfig, setMessageConfig] = useState({
    hasMessage: false,
    internal: true,
    external: true,
    externalMessageTypes: ['sms', 'whatsapp', 'telegram'],
    content: '',
  });

  function onChangeMessageConfig(checked, property) {
    setMessageConfig({ ...messageConfig, [property]: checked });
  }

  function CreateAlert(formValues) {
    console.log(`Form values`, {
      ...formValues,
      selectedActivitys,
      triggers,
      ...messageConfig,
    });
  }

  const activitys = [
    {
      label: 'Criar cateira',
      value: 'Criar cateira',
    },
    {
      label: 'Situação da criança',
      value: 'Situação da criança',
    },
    {
      label: 'Teste de Covid-19',
      value: 'Teste de Covid-19',
    },
    {
      label: 'Avisar sobre a rotina de exercícios de saúde',
      value: 'Avisar sobre a rotina de exercícios de saúde',
    },
  ];

  return (
    <Form layout="vertical" onFinish={CreateAlert}>
      <FormItem
        label="Nome do alerta"
        initialValue=""
        name="alert"
        item={<Input />}
      />

      <Activitys
        activityLabel="Atividades vinculadas ao alerta"
        activitys={activitys}
        selectedActivitys={selectedActivitys}
        setSelectedActivitys={setSelectedActivitys}
      />

      <Triggers
        activitys={selectedActivitys}
        triggers={triggers}
        setTriggers={setTriggers}
      />

      <FormItem
        name="active"
        initialValue
        valuePropName="checked"
        label="Alerta ativo"
        item={<Switch defaultChecked />}
      />

      <FormItem
        name="hasMessage"
        initialValue={false}
        valuePropName="checked"
        label="Disparar mensagem"
        item={
          <Switch
            onChange={(checked) => onChangeMessageConfig(checked, 'hasMessage')}
          />
        }
      />

      {messageConfig.hasMessage && (
        <MessagerSend
          onChange={onChangeMessageConfig}
          setMessageConfig={setMessageConfig}
          messageConfig={messageConfig}
          theme={theme}
        />
      )}
    
    </Form>
  );
}

export default memo(CreateOrEditAlerts);

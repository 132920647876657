import React, { memo, useState } from 'react';

import { message, Tag } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

import { Select } from '~/components/select';
import FormItem from '~/components/form/FormItem';
import Button from '~/components/button';
import Text from '~/components/typography/text';

import { FlexRow, Div, AntRow, AntCol, Margin } from './style';

function Activitys({ activitys, activityLabel, selectedActivitys, setSelectedActivitys }) {
  const [activityValue, setActivityValue] = useState(activitys[0].value);

  function AlreadySelected(activity) {
    const hasActivity = selectedActivitys.some((selected) => {
      return selected.value === activity;
    });

    return hasActivity;
  }

  function SelectedActivity(activity) {
    if (AlreadySelected(activity)) {
      message.error('Atividade já selecionada');
    } else {
      setSelectedActivitys([
        ...selectedActivitys,
        {
          value: activityValue,
          label: activityValue,
        },
      ]);
    }
  }

  return (
    <>
      <FormItem
        label={activityLabel}
        item={
          <FlexRow>
            <Div>
              <Select
                onChange={(value) => setActivityValue(value)}
                options={activitys}
              />
            </Div>

            <Button icon={<LinkOutlined />} onClick={() => SelectedActivity(activityValue)}>
              Vincular
            </Button>
          </FlexRow>
        }
      />
      {selectedActivitys.length > 0 ? (
        <AntRow>
          {selectedActivitys.map((tag) => (
            <AntCol key={tag.value} span={24}>
              <Tag
                onClose={() =>
                  setSelectedActivitys(
                    selectedActivitys.filter(
                      (activity) => activity.value !== tag.value
                    )
                  )
                }
                closable
              >
                {tag.label}
              </Tag>
            </AntCol>
          ))}
        </AntRow>
      ) : (
        <Margin >
          <Text>Nenhuma atividade vinculada</Text>
        </Margin>
      )}
    </>
  );
}

export default memo(Activitys);

import React, { memo } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import CreateOrEditAlerts from '../CreateOrEditAlerts';

import Title from '~/components/typography/title';
import Button from '~/components/button';
import Drawer from '~/components/drawer';

import { Container } from './style';

function Header({ isMobile }) {
  return (
    <Container>
      <Title level={4} style={{ margin: 0 }}>
        Alertas
      </Title>
      <Drawer
        placement="right"
        title="Criar alerta"
        width={isMobile ? '100%' : '40%'}
        Trigger={
          <Button type="primary" icon={<PlusOutlined />}>
            Novo alerta
          </Button>
        }
        DrawerContentComponent={CreateOrEditAlerts}
      />
    </Container>
  );
}

export default memo(Header);

import React, { useContext } from 'react';
// Libraries
import { Divider } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { ThemeContext } from '~/contexts/ThemeContext';

import Button from '~/components/button';
import Card from '~/components/card';
import Title from '~/components/typography/title';
import ProgramsTable from './components/ProgramsTable';

import { FlexDiv } from './style';

export default function Programs() {
  // Variables
  const { path } = useRouteMatch();
  const { theme } = useContext(ThemeContext);

  return (
    <Card>
      <FlexDiv>
        <Title level={4}>Meus Programas</Title>
        <Link to={`${path}/novo-programa`}>
          <Button text="Novo programa" type="primary" icon={<PlusOutlined />} />
        </Link>
      </FlexDiv>
      <Divider style={{ borderColor: theme.color.white }} />
      <ProgramsTable />
    </Card>
  );
}

import React from 'react';
// Libraries
import { List, Avatar, Divider, Typography } from 'antd';

import { GetDefaultAvatar } from '~/utils/SocialMeAssets';

import Button from '~/components/button';

const { Text } = Typography;

export default function Select({ peoples, setStep, setPeople }) {
  async function SelectPerson(person) {
    await setPeople(person);
    await setStep('fill');
  }
  return (
    <div>
      {peoples.length > 0 ? (
        <>
          <Divider>Resultado da busca</Divider>
          <List
            itemLayout="horizontal"
            dataSource={peoples}
            renderItem={(item) => (
              <List.Item
                style={{ display: 'flex', alignItems: 'center' }}
                actions={[
                  <Button
                    text="Vincular beneficiário"
                    type="primary"
                    onClick={() => SelectPerson(item)}
                  />,
                ]}
              >
                <List.Item.Meta
                  style={{ alignItems: 'center' }}
                  avatar={
                    <Avatar
                      src={item.avatar ? item.avatar : GetDefaultAvatar("male")}
                    />
                  }
                  title={item.name}
                />
              </List.Item>
            )}
          />
        </>
      ) : (
        <div
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flexDirection: 'column',
            display: 'flex',
            marginTop: 30,
          }}
        >
          <Text>Sua busca não retornou nenhum resultado</Text>
          <Button
            text="Buscar novamente"
            type="primary"
            onClick={() => setStep('search')}
            style={{ marginTop: 10 }}
          />
        </div>
      )}
    </div>
  );
}

import React, { useContext, memo } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Libraries
import { Checkbox, Row, Col } from 'antd';

function AntCheckboxGroup({ defaultValue, onChange, disabled, name, options }) {
  const { theme } = useContext(ThemeContext);

  return (
    <Checkbox.Group
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      name={name}
      style={{ color: theme.color.textOnSecondaryBackground, width: '100%' }}
    >
      <Row>
        {options.map((item) => (
          <Col style={{ padding: '5px 0' }} key={item.value} span={24}>
            <Checkbox value={item.value}>{item.label}</Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
}

export default memo(AntCheckboxGroup);

import React from 'react';
// Contexts
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import DebugContextProvider from '~/contexts/DebugContext';
import AxiosContextProvider from '~/contexts/AxiosContext';
import BrowserContextProvider from '~/contexts/BrowserContext';
import LanguageContextProvider from '~/contexts/LanguageContext';
import ResponsiveContextProvider from '~/contexts/ResponsiveContext';
import ThemeContextProvider from '~/contexts/ThemeContext';
import AuthContextProvider from '~/contexts/AuthContext';
import InterfaceContextProvider from '~/contexts/InterfaceContext';
import SidebarContextProvider from '~/contexts/SidebarContext';
// Assets
import '~/assets/css/antDesignCustom.css';
// Pages
// import Page from '~/pages/AuthenticationCheck';
import Page from '~/pages/ManagerCheck';

// Contexts
// Libraries
// Standalone Components
// Components
// Own Components
// Services
// Utils
// Environments
// Assets
// Pages
// Steps
// Views
// Destructuring

export default function App() {
  return (
    <Router>
      <DebugContextProvider>
        <AxiosContextProvider>
          <BrowserContextProvider>
            <LanguageContextProvider>
              <ResponsiveContextProvider>
                <ThemeContextProvider>
                  <AuthContextProvider>
                    <InterfaceContextProvider>
                      <SidebarContextProvider>
                        <Switch>
                          <Route path="/" component={Page} />
                        </Switch>
                      </SidebarContextProvider>
                    </InterfaceContextProvider>
                  </AuthContextProvider>
                </ThemeContextProvider>
              </ResponsiveContextProvider>
            </LanguageContextProvider>
          </BrowserContextProvider>
        </AxiosContextProvider>
      </DebugContextProvider>
    </Router>
  );
}

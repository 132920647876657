import React, { memo, useState } from 'react';

import { Select } from '~/components/select';
import { DataPicker } from '~/components/datapicker';

import { Container } from './style';

function Data({ InputStyle }) {
  const [dataRule, setDataRule] = useState('');

  const possibleDates = [
    {
      value: 'Hoje',
      label: 'Hoje',
    },
    {
      value: 'Amanhâ',
      label: 'Amanhâ',
    },
    {
      value: 'Ontem',
      label: 'Ontem',
    },
    {
      value: 'Na semana passada',
      label: 'Na semana passada',
    },
    {
      value: 'No mês passado',
      label: 'No mês passado',
    },
    {
      value: 'No ano passado',
      label: 'No ano passado',
    },
    {
      value: 'Data exata',
      label: 'Data exata',
    },
  ];
  return (
    <Container>
      <Select
        style={InputStyle}
        options={possibleDates}
        onChange={(value) => setDataRule(value)}
      />
      {dataRule === 'Data exata' && <DataPicker style={InputStyle} />}
    </Container>
  );
}

export default memo(Data);

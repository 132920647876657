import React from 'react';

import Button from '~/components/button';
import Table from '~/components/table';
import { ImageWithTextCell } from '~/components/table/cells';

export default function ColaboratorsTable() {
  // Variables
  const columns = [
    {
      title: 'Integrantes',
      dataIndex: 'member',
      key: 'member',
      width: '80%',
      render: (member, record) => (
        <ImageWithTextCell src={record.avatar} text={member} />
      ),
    },

    {
      title: 'Ações',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      render: () => <Button text="Desligar" />,
    },
  ];

  const data = [
    {
      id: '1',
      avatar: 'https://uifaces.co/our-content/donated/AW-rdWlG.jpg',
      member: 'Rogério da Silva Ribeiro',
    },
    {
      id: '2',
      avatar: 'https://uifaces.co/our-content/donated/DUhuoeI8.jpg',
      member: 'Sarah Conor',
    },
    {
      id: '3',
      avatar: 'https://randomuser.me/api/portraits/women/26.jpg',
      member: 'Maria Mendonça',
    },
    {
      id: '4',
      avatar: 'https://uifaces.co/our-content/donated/VxDQx_gA.jpg',
      member: 'Paulo Pedro',
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}

import React from 'react';

import Header from './components/Header';
import AlertsTable from './components/AlertsTable';

export default function Alerts() {
  return (
    <>
      <Header />
      <AlertsTable />
    </>
  );
}

import React, { memo } from 'react';

import Title from '~/components/typography/title';

import { Container } from './style';

function Header() {
  return (
    <Container>
      <Title level={4} style={{ margin: 0 }}>
        Alertas
      </Title>
    </Container>
  );
}

export default memo(Header);

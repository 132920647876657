import React, { useContext } from 'react';

import { Divider } from 'antd';
import { ThemeContext } from '~/contexts/ThemeContext';

import Header from './components/Header';
import BenefitsTable from './components/BenefitsTable';

export default function Benefits() {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Header />
      <Divider style={{ borderColor: theme.color.white }} />
      <BenefitsTable />
    </>
  );
}

import React from 'react';
// Libraries
import { Form, Row, Col, Divider } from 'antd';
// Components
import { SimpleInput } from '~/components/form/Questions';

export default function SearchPerson({ form }) {
  return (
    <div>
      <Divider>Busque o seu familiar</Divider>
      <Form
        labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
        form={form}
        size="small"
        layout="vertical"
        scrollToFirstError
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Name />
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <MotherName />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6}>
            <Birth />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <CPF />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <CNS />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <PIS />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const Name = () => {
  const data = {
    nameItem: ['filter', 'name'],
    labelItem: 'Nome',
    placeholder: 'Digite o nome da pessoa',
  };
  return SimpleInput(data);
};

const MotherName = () => {
  const data = {
    nameItem: ['filter', 'mother'],
    labelItem: 'Nome da mãe',
    placeholder: 'Digite o nome da mãe da pessoa',
    marginRight: true,
  };
  return SimpleInput(data);
};

const Birth = () => {
  const data = {
    nameItem: ['filter', 'birth'],
    labelItem: 'Nascimento',
    // width: '110px',
    //mask: '11/11/1111',
  };
  return SimpleInput(data);
};

const CPF = () => {
  const data = {
    nameItem: ['filter', 'cpf'],
    labelItem: 'CPF',
    // width: '150px',
    marginRight: true,
    //mask: '111.111.111-11',
  };
  return SimpleInput(data);
};

const CNS = () => {
  const data = {
    nameItem: ['filter', 'cns'],
    labelItem: 'CNS',
    marginRight: true,
    // width: '170px',
    //mask: '111 1111 1111 1111',
  };
  return SimpleInput(data);
};

const PIS = () => {
  const data = {
    nameItem: ['filter', 'pis'],
    labelItem: 'PIS',
    // width: '150px',
    //mask: '111.11111.11-1',
  };
  return SimpleInput(data);
};

import React, { useContext } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { ThemeContext } from '~/contexts/ThemeContext';

// Components
import Card from '~/components/card';

import { MainNavLink } from './style';

export default function Menu() {
  const { theme } = useContext(ThemeContext);

  const menuItems = [
    {
      to: 'atividades',
      index: 1,
      label: 'Atividade',
    },
    {
      to: 'alerta',
      index: 2,
      label: 'Alertas',
    },
    {
      to: 'beneficios',
      index: 3,
      label: 'Benefícios',
    },
    {
      to: 'informacao',
      index: 4,
      label: 'Informações',
    },
    {
      to: 'vincular-beneficiario',
      index: 5,
      label: 'Vincular beneficiário',
    },
    {
      to: 'visao-geral',
      index: 6,
      label: 'Visão geral',
    },
    {
      to: 'requisicoes',
      index: 7,
      label: 'Requisições',
    },
    {
      to: 'regras',
      index: 8,
      label: 'Regras',
    },
    {
      to: 'adicionar-coloborador',
      index: 9,
      label: 'Adicionar coloborador',
    },
    {
      to: 'central-de-comunicacoes',
      index: 10,
      label: 'Central de comunicações',
    },
    {
      to: 'gerenciar-alertas',
      index: 11,
      label: 'Gerencia de alertas',
    },
  ];

  const activeStyle = {
    color: theme.color.primary,
    backgroundColor: theme.color.white,
  };

  const { url } = useRouteMatch();

  const cardStyle = {
    borderRadius: 0,
    height: 'calc(100vh - 126px)',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: theme.color.primary,
    overflowY: 'auto',
  };

  return (
    <Card
      bodyStyle={{ padding: '10px 0 10px 10px' }}
      bordered={false}
      style={cardStyle}
    >
      {menuItems.map((menuItem) => (
        <MainNavLink
          activeStyle={activeStyle}
          key={menuItem.to}
          theme={theme}
          to={`${url}/${menuItem.to}`}
        >
          {menuItem.label}
        </MainNavLink>
      ))}
    </Card>
  );
}

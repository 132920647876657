import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #d9d9d9;
`;

export const Content = styled.div`
  width: 100%;
  padding: 10px;
`;

export const Footer = styled.div`
  width: 100%;
  padding: 15px;
  background-color: green;
  border: 1px solid #d9d9d9;
`;

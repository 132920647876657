import React from 'react';

import Header from './components/Header';
import IntegrantionsTable from './components/IntegrantionsTable';

import { Container } from './style';

export default function AddColaborators() {
  return (
    <Container>
      <Header />
      <IntegrantionsTable />
    </Container>
  );
}

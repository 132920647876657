import React from 'react';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';

export default function Benefits() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 20,
      }}
    >
      <div
        style={{
          width: '80%',
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <img
            src="https://socialme-assets.s3.us-east-1.amazonaws.com/images/imageTeste3.png"
            alt="..."
            style={{ display: 'block', margin: '0px auto' }}
          />
        </div>
        <div>
          <Title level={4} textAlign="center">
            Phasellus tempor ut purus vitae luctus. Pellentesque posuere diam et
            mauris efficitur, non pretium tellus vestibulum. Nulla efficitur
            justo id dolor ultricies, quis mollis neque consequat. Nunc cursus
            in nunc quis suscipit. Etiam urna sem, viverra facilisis lorem at,
            condimentum imperdiet eros. Sed nec molestie libero, at euismod
            lectus. Vivamus placerat tincidunt ultricies. Nunc justo mauris,
            faucibus sed dolor id, maximus vulputate sapien.
          </Title>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin: '20px auto',
            flexDirection: 'column',
            width: 200,
          }}
        >
          <a href="https://accounts.socialme.com.br">
            <Button
              type="primary"
              size="large"
              style={{ margin: '0px auto', display: 'block' }}
            >
              Entrar no Social Me
            </Button>
          </a>
          <a href="https://accounts.socialme.com.br">
            <Button type="link" style={{ marginTop: 10 }}>
              Criar uma conta Social Me
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

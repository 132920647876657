import React from 'react';
// Libraries
import { Link } from 'react-router-dom';
import { Divider, Typography, Card } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// Components
import Form from './components/Form';
import Button from '~/components/button';

const { Title } = Typography;

export default function AddMaintenanceRules() {
  return (
    <div>
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Title level={4}>Nova regra de benefício</Title>
          </div>
          <div>
            <Link to="programas/programa/regras/regras-de-beneficios">
              <Button
                text="Minhas regras de benefícios"
                icon={<ArrowLeftOutlined />}
                type="dashed"
              />
            </Link>
          </div>
        </div>
        <Divider />
        <Form />
      </Card>
    </div>
  );
}

import React, { useEffect } from 'react';

import { Header, Content, Footer } from './style';

export default function Dashboard() {
  useEffect(() => {
    const contentPerPage = [];
    let currentElementsSize = 0;
    let newContentElementPerPage = document.createElement('div');

    function VerifyLimitSize(currentContentSize) {
      const pdfSize = 300;
      const headerSize = document.getElementById('header').offsetHeight;
      const footerSize = document.getElementById('footer').offsetHeight;

      let limit = pdfSize - headerSize - footerSize;

      return currentContentSize > limit;
    }

    function AddElement(element) {
      if (element.offsetHeight) currentElementsSize += element.offsetHeight;

      const cloneChild = element.cloneNode(true);

      newContentElementPerPage.appendChild(cloneChild);
    }

    const content = document.getElementById('editor');

    content.innerHTML = ` TEEEEE, ipsum dolor sit amet consectetur adipisicing elit. Amet
    quibusdam eos alias debitis ex, commodi nostrum accusamus facilis! Eius
    vitae hic doloribus assumenda minus culpa id sunt sed placeat libero?
    <div id="1">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="2">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="3">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="4">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="5">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="6">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="7">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="8">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="9">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="10">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="11">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="12">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>
    <div id="13">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
      quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
      Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
      libero?
    </div>`;

    console.log('content', content);

    content.childNodes.forEach((child) => {
      if (child.offsetHeight) {
        if (VerifyLimitSize(currentElementsSize + child.offsetHeight)) {
          contentPerPage[contentPerPage.length] =
            newContentElementPerPage.innerHTML;

          newContentElementPerPage = document.createElement('div');

          currentElementsSize = 0;

          currentElementsSize += child.offsetHeight;
        } else {
          AddElement(child);
        }
      } else {
        AddElement(child);
      }

      if (content.lastChild === child && currentElementsSize > 0) {
        AddElement(child);

        contentPerPage[contentPerPage.length] =
          newContentElementPerPage.innerHTML;
      }
    });

    console.log(`contentPerPage`, contentPerPage);
  }, []);

  return (
    <>
      <Header id="header">
        <h1>TITULOS</h1>
      </Header>
      <Content id="editor">
        TEEEEE, ipsum dolor sit amet consectetur adipisicing elit. Amet
        quibusdam eos alias debitis ex, commodi nostrum accusamus facilis! Eius
        vitae hic doloribus assumenda minus culpa id sunt sed placeat libero?
        <div id="1">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="2">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="3">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="4">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="5">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="6">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="7">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="8">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="9">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="10">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="11">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="12">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
        <div id="13">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet
          quibusdam eos alias debitis ex, commodi nostrum accusamus facilis!
          Eius vitae hic doloribus assumenda minus culpa id sunt sed placeat
          libero?
        </div>
      </Content>
      <Footer id="footer">
        <p>Rodapé</p>
      </Footer>
    </>
  );
}

import React from 'react';

import Title from '~/components/typography/title';
import Button from '~/components/button';
import Tooltip from '~/components/tooltip';

import { ButtonContainer, Div } from './style';

export default function Types({ triggerType, setTriggerType }) {
  function VerifyTriggerType(currentType) {
    return currentType === triggerType;
  }

  return (
    <>
      <Title style={{ textAlign: 'center' }} level={4}>
        Tipos de alertas
      </Title>
      <ButtonContainer>
        <Tooltip title="Crie regras para atividades relacionadas a dias/horários ">
          <Div>
            <Button
              type={VerifyTriggerType('absence') && 'primary'}
              onClick={() => setTriggerType('absence')}
            >
              Ausência
            </Button>
          </Div>
        </Tooltip>
        <Tooltip title="Crie Regras de acordo com informaççoes da atividade">
          <Div>
            <Button
              type={VerifyTriggerType('custom') && 'primary'}
              onClick={() => setTriggerType('custom')}
            >
              Customizado
            </Button>
          </Div>
        </Tooltip>
        <Tooltip title="Crie Regras para verificar a realização das atividades pelos benefiários">
          <Div>
            <Button
              type={VerifyTriggerType('presence') && 'primary'}
              onClick={() => setTriggerType('presence')}
            >
              Presença
            </Button>
          </Div>
        </Tooltip>
      </ButtonContainer>
    </>
  );
}

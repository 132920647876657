import React from 'react';
// Libraries
import { DeleteOutlined } from '@ant-design/icons';
// Components

import { BadgeCell, ImageWithTextCell } from '~/components/table/cells';
import Button from '~/components/button';
import Table from '~/components/table';
// Assets
import image7 from '~/assets/images/icons/beneficio7.svg';
import image1 from '~/assets/images/icons/beneficio4.svg';
import image2 from '~/assets/images/icons/beneficio5.svg';
import image3 from '~/assets/images/icons/beneficio6.svg';

import { FlexStart } from './style'

export default function TableActivity() {
  // Variables
  const columns = [
    {
      title: 'Benefício',
      dataIndex: 'benefit',
      key: 'benefit',
      render: (benefit, record) => (
        <ImageWithTextCell
          src={record.image}
          text={benefit}
          size={40}
        />
      ),
    },
    {
      title: 'Situação',
      dataIndex: 'situation',
      key: 'situation',
      render: (situation) => (
        <BadgeCell
          text={situation}
          status={situation === 'Em estoque' ? 'success' : 'error'}
        />
      ),
    },
    {
      title: 'Gasto total',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Ações',
      dataIndex: 'key',
      key: 'key',
      render: () => (
        <FlexStart>
          <Button type="dashed" style={{ marginRight: 5 }}>
            Editar
          </Button>
          <Button icon={<DeleteOutlined />} />
        </FlexStart>
      ),
    },
  ];

  const data = [
    {
      key: '54',
      benefit: 'Cesta Básica',
      image: image7,
      quantidade: '57',
      value: 'R$ 4.440,00',
      situation: 'Em estoque',
    },
    {
      key: '1',
      benefit: 'Kit de Limpeza',
      image: image1,
      quantidade: '57',
      value: 'R$ 2.200,00',
      situation: 'Em estoque',
    },
    {
      key: '2',
      benefit: 'Roupas',
      image: image2,
      quantidade: '100',
      value: 'R$ 6.500,00',
      situation: 'Em estoque',
    },
    {
      key: '3',
      benefit: 'Kit de Higiene',
      image: image3,
      quantidade: '50',
      value: 'R$ 3.600,00',
      situation: 'Em falta',
    },
  ];
  return <Table pagination={false} tableColumns={columns} dataReceived={data} />;
}

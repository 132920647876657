import React from 'react';

import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import Card from '~/components/card';
import Header from '~/components/header/type1';
import Title from '~/components/typography/title';
import Button from '~/components/button';

export default function AddActivities() {
  const headerLeftElements = () => {
    return <Title level={4}>Nova Atividade</Title>;
  };
  const headerRightElements = () => {
    return (
      <Link to="/atividades">
        <Button text="Voltar" type="dashed" icon={<ArrowLeftOutlined />} />
      </Link>
    );
  };
  return (
    <div>
      <Card>
        <Header
          LeftElements={headerLeftElements}
          RightElements={headerRightElements}
        />
        <Divider />
        <div
          style={{
            height: 300,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Title level={4} style={{ color: '#bdbdbd' }}>
            Planejar Tela
          </Title>
        </div>
      </Card>
    </div>
  );
}

import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

export const Div = styled.div``;

export const MainNavLink = styled(NavLink)`
  display: inline-block;
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px 0px 0px 8px;
  color: ${(props) => props.theme.color.textOnPrimaryBackground};

  &:hover {
    color: ${(props) => props.theme.color.white};
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
`;

export const SwitchContainer = styled.div`
  padding: 5px;
`;

export const MessageSendTypeContainer = styled.div`
  padding: 0 40px;
`;

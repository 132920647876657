import styled from 'styled-components';

import System from '~/environments/System';

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    flex-direction: column;
  }
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${`${System.ResponsiveBreakpoint}px`}) {
    margin-bottom: 10px;
    flex-direction: column;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

import React, { memo } from 'react';

import Space from '~/components/space';

import DatePicker from './style';

function AntDatapicker({ onChange, picker }) {
  return (
    <Space direction="vertical">
      <DatePicker onChange={onChange} />
      <DatePicker onChange={onChange} picker="week" />
      <DatePicker onChange={onChange} picker="month" />
      <DatePicker onChange={onChange} picker="quarter" />
      <DatePicker onChange={onChange} picker="year" />
    </Space>
  );
}

export default memo(AntDatapicker);

import React, { memo } from 'react';

import DatePicker from './style';

function AntDatapicker({ onChange, picker, placeholder, size, style }) {
  return (
    <DatePicker
      placeholder={placeholder || 'Selecione uma data'}
      onChange={onChange}
      picker={picker || 'date'}
      size={size}
      style={style}
    />
  );
}

export default memo(AntDatapicker);

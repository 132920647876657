import styled from 'styled-components';

import { Row, Col } from 'antd';

export const AntCol = styled(Col)`
  margin-bottom: 5px;

  &::last-child {
    margin: 0;
  }
`;

export const AntRow = styled(Row)`
  margin: 10px 0;
`;

export const Div = styled.div`
  width: 100%;
  margin-right: 10px;
`;

export const Margin = styled.div`
  margin-bottom: 10px
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { memo, useState } from 'react';

import Button from '~/components/button';
import CustomInput from '~/components/customInput';
import Divider from '~/components/divider';
import Activitys from '../../../../../Activitys';

import { AntRow, AntCol } from './style';

function Absence({ theme, activitys }) {
  const [rules, setRules] = useState([]);
  const [triggerActivitys, setTriggerActivitys] = useState([]);

  console.log(`triggerActivitys`, triggerActivitys);

  return (
    <>
      <Activitys
       activityLabel="Atividades vinculadas ao gatilho"
        activitys={activitys}
        selectedActivitys={triggerActivitys}
        setSelectedActivitys={setTriggerActivitys}
      />
        <Divider />
      <Button onClick={() => setRules([...rules, 'Regra para data'])}>
        Nova regra 
      </Button>
   
    
      {rules.length > 0 ? (
        <AntRow>
          {rules.map((rule) => (
            <AntCol key={rule} span={24}>
              <CustomInput
                setRules={setRules}
                rules={rules}
                enabledTypes={{ data: true, text: false, number: false }}
                customText={rule}
              />
            </AntCol>
          ))}
        </AntRow>
      ) : (
        <div />
      )}
    </>
  );
}

export default memo(Absence);

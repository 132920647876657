import React, { useContext } from 'react';
// Libraries
import { Divider } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { ThemeContext } from '~/contexts/ThemeContext';

// Components
import Card from '~/components/card';
import Title from '~/components/typography/title';
import Button from '~/components/button';
// Own Components
import ActivityTable from './components/ActivityTable';

import { HeaderContainer } from './style'

export default function MyActivities() {
  const { path } = useRouteMatch();

  const { theme } = useContext(ThemeContext);

  return (
    <Card>
      <HeaderContainer>
        
        <Title level={4}>Minhas Atividades</Title>
       
       
        <Link to={`${path}/nova-atividade`}>
          <Button
            text="Nova Atividade"
            icon={<PlusOutlined />}
            type="primary"
          />
        </Link>

      </HeaderContainer>
      
      <Divider style={{ borderColor: theme.color.white }}/>
      <ActivityTable />
    </Card>
  );
}

import React from 'react';

import { Link } from 'react-router-dom';
// Components
import Avatar from '~/components/avatar';
import Title from '~/components/typography/title';
import Card from '~/components/card';
import Button from '~/components/button';

import image1 from '~/assets/images/icons/program1.svg';

import { FlexBox, Content } from './style';

export default function Header() {
  const cardStyle = {
    marginBottom: 10,
  };

  return (
    <Card style={cardStyle}>
      <Content>
        <FlexBox>
          <Avatar style={{ marginRight: 10 }} src={image1} />
          <Title style={{ margin: 0 }} level={4}>
            Programa: Carteira da Criança
          </Title>
        </FlexBox>

        <FlexBox>
          <Link to="/programas">
            <Button>Meus programas</Button>
          </Link>
        </FlexBox>
      </Content>
    </Card>
  );
}

import styled from 'styled-components';

import { Select } from 'antd';

const { Option, OptGroup } = Select;

export const MainSelect = styled(Select)`
  width: ${(style) => style.width || '100%'};
  background-color: ${(style) => style.backgroundcolor};
  color: ${(style) => style.color};
  margin: ${(style) => style.margin};
  border-radius: 8px;

  &.ant-select-selector {
    border: none;
  }
  .ant-select-selector {
    border: none;
  }
  &.ant-select-selector {
    border: none;
  }
`;

export const MainOption = styled(Option)`
  background: ${(style) => style.backgroundColor};
  color: ${(style) => style.color};
`;

export const MainOptionGroup = styled(OptGroup)`
  background: ${(style) => style.backgroundColor};
  color: ${(style) => style.color};
`;

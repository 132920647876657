import styled from 'styled-components';

export const Div = styled.div``;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-around;
`;

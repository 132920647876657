import React from 'react';

import Header from './components/Header';
import ColaboratorsTable from './components/ColaboratorsTable';

import { Container } from './style';

export default function AddColaborators() {
  return (
    <Container>
      <Header />
      <ColaboratorsTable />
    </Container>
  );
}

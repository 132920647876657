import React from 'react';

import Header from './components/Header';
import ActivitysTable from './components/ActivitysTable';

export default function Activitys() {
  return (
    <>
      <Header />
      <ActivitysTable />
    </>
  );
}

import React, { useContext } from 'react';

import { RiMiniProgramLine } from 'react-icons/ri';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { MdLocalActivity } from 'react-icons/md';
import { AiOutlineHome, AiOutlineTransaction } from 'react-icons/ai';

import { Route, Switch, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { ThemeContext } from '~/contexts/ThemeContext';

import Sidebar from '~/components/sidebar';
import MainNavbar from '~/components/navbar/Main';

import Home from './pages/Home';
import Activities from './pages/Activities';
import Moves from './pages/Moves';
import Programs from './pages/Programs';
import Benefits from './pages/Benefits';

const { Content } = Layout;

export default function Profile() {
  const { theme } = useContext(ThemeContext);

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Início',
      icon: AiOutlineHome,
      url: '/inicio',
    },
    {
      index: 2,
      title: 'Atividades',
      icon: MdLocalActivity,
      url: '/atividades',
    },
    {
      index: 3,
      title: 'Benefícios',
      icon: FaHandHoldingHeart,
      url: '/beneficios',
    },
    {
      index: 4,
      title: 'Movimentações',
      icon: AiOutlineTransaction,
      url: '/movimentacoes',
    },
    {
      index: 5,
      title: 'Programas',
      icon: RiMiniProgramLine,
      url: '/programas',
    },
    {
      index: 6,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <MainNavbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <Switch>
              <Route exact path="/">
                <Redirect to="/inicio" />
              </Route>
              <Route exact path="/inicio" component={Home} />
              <Route path="/atividades" component={Activities} />
              <Route path="/movimentacoes" component={Moves} />
              <Route path="/programas" component={Programs} />
              <Route path="/beneficios" component={Benefits} />
              <Route exact path="*">
                <Redirect to="/inicio" />
              </Route>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

import styled from 'styled-components';

export const ButtonContainer = styled.div`
  padding: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FlexBox = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

import React from 'react';
// Libraries

import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import Tabs from '~/components/tabs'
// Pages
import Benefits from './pages/Benefits';
import Products from './pages/Products';
import Services from './pages/Services';

import { Container } from './style'

export default function BenefitsContent() {
  const { path } = useRouteMatch();
  
  const tabsItems = [{
    tabPath: `/`,
    title: "Benefícios"
  },
  {
    tabPath: `/produtos`,
    title: "Produtos"
  },
  {
    tabPath: `/servicos`,
    title: "Serviços"
  }]
  
  return (
    <div>
      
      <Container>
        <Tabs tabsItems={tabsItems} />
          {/* <Badge count={0}>
          <Link to={`${path}`}>
            <Button
              style={{ marginRight: 10 }}
              type={
                location.pathname === `${path}` ||
                location.pathname === `${path}/`
                  ? 'primary'
                  : 'default'
              }
              shape="square"
              size="large"
            >
              Benefícios
            </Button>
          </Link>
        </Badge>
        <Badge count={0}>
          <Link to={`${path}/produtos`}>
            <Button
              style={{ marginRight: 10 }}
              type={
                location.pathname === `${path}/produtos` ? 'primary' : 'default'
              }
              shape="square"
              size="large"
            >
              Produtos
            </Button>
          </Link>
        </Badge>
        <Badge count={0}>
          <Link to={`${path}/servicos`}>
            <Button
              type={
                location.pathname === `${path}/servicos` ? 'primary' : 'default'
              }
              shape="square"
              size="large"
            >
              Serviços
            </Button>
          </Link>
            </Badge> */}
      </Container>

      <Switch>
        <Route exact path={`${path}`} component={Benefits} />
        <Route path={`${path}/produtos`} component={Products} />
        <Route path={`${path}/servicos`} component={Services} />
      </Switch>
    </div>
  );
}

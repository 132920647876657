import styled from 'styled-components';

export const IconDragger = styled.p`
  margin-bottom: 20px;
  color: #a8201b;
  font-size: 48px;
  display: flex;
  flex-direction: column;
`;
export const TextDragger = styled.p`
  padding: 10px;
`;

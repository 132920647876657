import React from 'react';
// Libraries
import { DeleteOutlined } from '@ant-design/icons';
// Components
import Button from '~/components/button';
import {
  TagsCell,
  ImageWithTextCell,
  PrimaryTextCell,
} from '~/components/table/cells';
import Table from '~/components/table';
// Assets
import image1 from '~/assets/images/icons/product1.svg';
import image2 from '~/assets/images/icons/product2.svg';
import image3 from '~/assets/images/icons/product3.svg';
import image4 from '~/assets/images/icons/product4.svg';
import image6 from '~/assets/images/icons/product6.svg';

import { FlexStart } from './style';

export default function TableActivity() {
  // Variables
  const columns = [
    {
      title: 'Produto',
      dataIndex: 'product',
      key: 'product',
      render: (product, record) => (
        <ImageWithTextCell src={record.image} text={product} />
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      render: (description) => <PrimaryTextCell text={description} />,
    },
    /*
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: () => <TagsCell />,
    },
    */
    {
      title: 'Ações',
      dataIndex: 'key',
      key: 'key',
      align: 'left',
      render: (action) => (
        <FlexStart>
          <Button type="dashed" style={{ marginRight: 5 }}>
            Editar
          </Button>
          <Button icon={<DeleteOutlined />} />
        </FlexStart>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      product: 'Produto 1',
      image: image1,
      description: 'Descrição do produto',
      tags: ['tag1', 'tag2'],
    },
    {
      key: '2',
      product: 'Produto 2',
      image: image2,
      description: 'Descrição do produto',
      tags: ['tag2', 'tag2'],
    },
    {
      key: '3',
      product: 'Produto 3',
      image: image3,
      description: 'Descrição do produto',
      tags: ['tag3', 'tag3'],
    },
    {
      key: '4',
      product: 'Produto 4',
      image: image4,
      description: 'Descrição do produto',
      tags: ['tag4', 'tag4'],
    },
    {
      key: '5',
      product: 'Produto 5',
      image: image6,
      description: 'Descrição do produto',
      tags: ['tag5', 'tag5'],
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}

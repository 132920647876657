import styled from 'styled-components';

import { CloseCircleFilled } from '@ant-design/icons';

export const Container = styled.div`
  margin: 10px 0;
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseIcon = styled(CloseCircleFilled)`
  font-size: 20px;
  color: ${(props) => props.color};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
`;

import React, { useContext, useState } from 'react';
// Libraries
import MaskedInput from 'antd-mask-input';
import {
  Form,
  Radio,
  Input,
  Typography,
  Checkbox,
  InputNumber,
  Select,
  Upload,
  message,
  Spin,
  Alert,
  Tooltip,
} from 'antd';
import {
  InboxOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  FileProtectOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
// Standalone components
import { IconDragger, TextDragger } from './style';
// Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { ThemeContext } from '~/contexts/ThemeContext';
import { AxiosContext } from '~/contexts/AxiosContext';

const { Option } = Select;
const { Dragger } = Upload;
const { Text } = Typography;

export function SimpleInput(data) {
  const { isMobile } = useContext(ResponsiveContext);
  const HelpMessage = () => {
    if (data.helpText) {
      return (
        <Tooltip title={data.helpText}>
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <Form.Item
      name={data.nameItem}
      label={
        <span>
          {data.labelItem} {HelpMessage()}
        </span>
      }
      rules={data.rules}
      hasFeedback
      validateFirst
      style={{
        marginRight: data.marginRight ? 10 : 0,
      }}
    >
      {data.mask ? (
        <MaskedInput
          disabled={!!data.disabled}
          mask={data.mask}
          // placeholderChar=" "
          size={isMobile ? 'large' : 'default'}
          placeholder={data.placeholder}
          type={data.type ? data.type : 'text'}
          prefix={data.prefix ? data.prefix : false}
          style={{
            width: data.width ? data.width : '100%',
            textTransform: 'uppercase',
          }}
        />
      ) : (
        <Input
          disabled={!!data.disabled}
          size={isMobile ? 'large' : 'default'}
          placeholder={data.placeholder}
          type={data.type ? data.type : 'text'}
          prefix={data.prefix ? data.prefix : false}
          style={{
            width: data.width ? data.width : '100%',
            textTransform: 'uppercase',
          }}
        />
      )}
    </Form.Item>
  );
}

export function SimpleSelect(data) {
  const { isMobile } = useContext(ResponsiveContext);
  const HelpMessage = () => {
    if (data.helpText) {
      return (
        <Tooltip title={data.helpText}>
          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
      );
    }
    return null;
  };
  return (
    <Form.Item
      name={data.nameItem}
      label={
        <span>
          {data.labelItem} {HelpMessage()}
        </span>
      }
      hasFeedback
      style={{
        marginRight: data.marginRight ? 10 : 0,
      }}
    >
      <Select
        size={isMobile ? 'large' : 'default'}
        placeholder={data.placeholder}
        style={{
          textTransform: 'uppercase',
          width: data.width ? data.width : '100%',
        }}
      >
        {data.options.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export function SimpleDragger(values, SubsequentFunctions) {
  console.log(values);
  // Contexts
  const { CallToTheAPI } = useContext(AxiosContext);
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);
  // Variables
  const [load, setLoad] = useState();
  const [result, setResult] = useState(false);
  // Functions
  async function UploadDocument(file) {
    const data = new FormData();
    data.append('file', file);
    values.axiosParams.data = data;
    console.log(values.axiosParams);
    const response = await CallToTheAPI(values.axiosParams);
    //console.log(response);
    if (response.success) {
      message.success(values.successMessage);
      setLoad(false);
      setResult(true);
      return SubsequentFunctions(response);
    }
  }
  const props = {
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      setLoad(true);
      UploadDocument(file);
      return false;
    },
  };
  const RenderDraggerBody = () => {
    if (result) {
      return (
        <Alert
          message={values.successMessage}
          description="Você pode enviar um novo arquivo sempre que quiser 😀"
          type="success"
          showIcon
          style={{ border: 'none' }}
        />
      );
    }
    if (values.file) {
      return (
        <>
          <IconDragger>
            <FileProtectOutlined style={{ color: theme.color.primary }} />
            <span style={{ fontSize: '10pt' }}>{values.alreadyHasMessage}</span>
          </IconDragger>
          <TextDragger>
            {isMobile ? values.uploadMessageMobile : values.uploadMessage}
          </TextDragger>
        </>
      );
    }
    return (
      <>
        <IconDragger>
          <CloudUploadOutlined />
        </IconDragger>
        <TextDragger style={{ color: theme.color.primary }}>
          {isMobile ? values.uploadMessageMobile : values.uploadMessage}
        </TextDragger>
      </>
    );
  };
  return (
    <Dragger {...props}>
      {load ? (
        <Spin
          style={{ color: theme.color.primary }}
          indicator={
            <LoadingOutlined
              style={{ fontSize: 24, color: theme.color.primary }}
              spin
            />
          }
          tip="Enviando documento..."
        />
      ) : (
        RenderDraggerBody()
      )}
    </Dragger>
  );
}

export const SimpleUpload = (data) => {
  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Form.Item label="Enviar Arquivo">
      <Form.Item
        name="dragger"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        noStyle
      >
        <Upload.Dragger name="files" action="/upload.do">
          <IconDragger>
            <InboxOutlined />
          </IconDragger>
          <TextDragger>
            {isMobile
              ? 'Clique aqui para enviar seu documento'
              : 'Clique ou arraste para cá seus arquivos para enviar'}
          </TextDragger>
        </Upload.Dragger>
      </Form.Item>
    </Form.Item>
  );
};

export function SingleAnswerQuestion(data) {
  return (
    <Form.Item name={data.nameItem} label={data.labelItem}>
      <Radio.Group
        style={{
          paddingLeft: 15,
          display: data.display ? data.display : 'flex',
          flexDirection: data.radios.length <= 2 ? 'row' : 'column',
        }}
      >
        {data.radios.map((radio, index) =>
          radio.label ? (
            <Radio
              key={index}
              value={
                radio.value === true || radio.value === false
                  ? radio.value
                  : radio.value.toUpperCase()
              }
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {radio.label}
            </Radio>
          ) : (
            <Radio
              key={index}
              value={
                radio.value === true || radio.value === false
                  ? radio.value
                  : radio.value.toUpperCase()
              }
            >
              <img
                style={{ width: 50, height: 50 }}
                src={radio.image}
                alt="..."
              />
            </Radio>
          )
        )}
      </Radio.Group>
    </Form.Item>
  );
}

export const MultipleAnswerQuestion = (data) => {
  return (
    <Form.Item
      name={data.nameItem}
      label={<span>{data.labelItem}</span>}
      style={{ display: 'block' }}
    >
      <Checkbox.Group style={{ width: '100%', marginTop: 10, display: 'grid' }}>
        {data.checkboxs.map((checkbox, index) => (
          <Checkbox
            style={index === 0 ? { marginLeft: 8 } : {}}
            key={index}
            value={checkbox.value.toUpperCase()}
          >
            <Text>{checkbox.label}</Text>
          </Checkbox>
        ))}
      </Checkbox.Group>
    </Form.Item>
  );
};

export const SingleAnswerQuestion2 = (data) => {
  return (
    <Form.Item name={data.nameItem} label={data.labelItem}>
      <Radio.Group
        style={{
          paddingLeft: 15,
          display: data.display ? data.display : 'flex',
        }}
      >
        {data.radios.map((radio, index) =>
          radio.label ? (
            <Radio
              key={index}
              value={
                radio.value === true || radio.value === false
                  ? radio.value
                  : radio.value.toUpperCase()
              }
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {radio.label}
            </Radio>
          ) : (
            <Radio
              key={index}
              value={
                radio.value === true || radio.value === false
                  ? radio.value
                  : radio.value.toUpperCase()
              }
            >
              <img
                style={{ width: 50, height: 50 }}
                src={radio.image}
                alt="..."
              />
            </Radio>
          )
        )}
      </Radio.Group>
    </Form.Item>
  );
};

export const MultipleFieldQuestion = (data) => {
  return (
    <Form.Item label={data.labelItem}>
      <Input.Group style={{ paddingLeft: 15 }}>
        {data.daughtersQuestions.map((daughter, index) => (
          <Form.Item
            key={index}
            name={daughter.name}
            label={daughter.label}
            style={{ display: 'flex' }}
          >
            {daughter.inputType === 'number' ? (
              <InputNumber
                min={1}
                max={7}
                defaultValue={0}
                style={{ width: daughter.inputWidth }}
              />
            ) : (
              <Input type="text" style={{ width: daughter.inputWidth }} />
            )}

            {/* 
                        <div style={{ display: 'flex', marginTop: 5 }}>
                            <Text style={{ marginRight: daughter.textMarginRight }}>{daughter.label}</Text>
                            <Input type={daughter.inputType} style={{ width: daughter.inputWidth }} />
                        </div>
                        */}
          </Form.Item>
        ))}
      </Input.Group>
    </Form.Item>
  );
};

/*
export const SimpleInput = (data) => {
  // const { isMobile } = useContext(ResponsiveContext);
  return (
    <Form.Item
      name={data.nameItem}
      label={data.labelItem}
      rules={data.rules}
      style={{
        marginRight: data.marginRight ? 10 : 0,
      }}
    >
      <Input
        disabled={!!data.disabled}
        size={data.size ? data.size : 'small'}
        placeholder={data.placeholder}
        style={{
          width: data.width ? data.width : '100%',
          textTransform: 'uppercase',
        }}
      />
    </Form.Item>
  );
};
*/

export const SimpleTextArea = (data) => {
  return (
    <Form.Item
      name={data.nameItem}
      label={data.labelItem}
      rules={data.rules}
      style={{
        marginRight: data.marginRight ? 10 : 0,
      }}
    >
      <Input.TextArea rows={data.rows} />
    </Form.Item>
  );
};

export const SimpleInputNumber = (data) => {
  return (
    <Form.Item
      name={data.nameItem}
      label={data.labelItem}
      rules={data.rules}
      style={{
        marginRight: data.marginRight ? 10 : 0,
      }}
    >
      <InputNumber
        min={data.min}
        max={data.max}
        placeholder={data.placeholder}
        style={{ width: data.width ? data.width : '100%' }}
      />
    </Form.Item>
  );
};

import React from 'react';
// Libraries
import { EyeOutlined } from '@ant-design/icons';

import { ImageWithTextCell, PrimaryTextCell } from '~/components/table/cells';

import Table from '~/components/table';
import Drawer from '~/components/drawer';
import Button from '~/components/button';
// Assets
import perfil1 from '~/assets/images/perfil/perfil1.jpg';
import perfil2 from '~/assets/images/perfil/perfil2.jpg';
import perfil3 from '~/assets/images/perfil/perfil3.jpg';
import perfil4 from '~/assets/images/perfil/perfil4.jpg';
import perfil5 from '~/assets/images/perfil/perfil5.jpg';
import perfil6 from '~/assets/images/perfil/perfil6.jpg';
import perfil7 from '~/assets/images/perfil/perfil7.jpg';
import perfil8 from '~/assets/images/perfil/perfil8.jpg';
import perfil9 from '~/assets/images/perfil/perfil9.jpg';
import perfil10 from '~/assets/images/perfil/perfil10.jpg';

export default function ProgramActivitysTable() {
  // Variables
  const columns = [
    {
      title: 'Pessoa',
      dataIndex: 'image',
      key: 'image',
      width: '30%',
      render: (image, record) => (
        <ImageWithTextCell src={image} text={record.name} />
      ),
    },
    {
      title: 'Atividade',
      dataIndex: 'activityKind',
      key: 'activityKind',
      width: '40%',
      render: (activityKind) => <PrimaryTextCell text={activityKind} />,
    },
    {
      title: 'Ações',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: '25%',
      render: (key) => (
        <Drawer
          width="40vw"
          title="Mais Detalhes"
          Trigger={<Button text="Mais informações" icon={<EyeOutlined />} />}
        />
      ),
    },
  ];

  const data = [
    {
      id: '1',
      name: 'Ana Paula',
      image: perfil1,
      activityKind: 'Teste de COVID-19',
    },
    {
      id: '2',
      name: 'Marlon Silva',
      image: perfil2,
      activityKind: 'Teste de COVID-19',
    },
    {
      id: '3',
      name: 'Rogerio Silva',
      image: perfil3,
      activityKind: 'Teste de COVID-19',
    },
    {
      id: '4',
      name: 'Paulo Roberto',
      image: perfil4,
      activityKind: 'Exame de diabates',
    },
    {
      id: '5',
      name: 'Matheus Carvalho',
      image: perfil5,
      activityKind: 'Fisioterapia',
    },
    {
      id: '6',
      name: 'Matheus Carvalho',
      image: perfil6,
      activityKind: 'Fisioterapia',
    },
    {
      id: '7',
      name: 'Melissa Roman',
      image: perfil7,
      activityKind: 'Exame de sangue',
    },
    {
      id: '8',
      name: 'Paula Mendes',
      image: perfil8,
      activityKind: 'Consulta ao médico',
    },
    {
      id: '9',
      name: 'Leticia Dias',
      image: perfil9,
      activityKind: 'Consulta ao dentista',
    },
    {
      id: '10',
      name: 'Geovanna Carvalho',
      image: perfil10,
      activityKind: 'Teste de gravidez',
    },
  ];
  return (
    <Table pagination={false} tableColumns={columns} dataReceived={data} />
  );
}

import React, { memo, useState } from 'react';

import InputNumber from '~/components/inputNumber'
import FormItem from '~/components/form/FormItem'
import Activitys from '../../../../../Activitys';

function Presence({ activitys }) {
  const [triggerActivitys, setTriggerActivitys] = useState([]);
  const [frequencie, setFrequencie ] = useState(0);

  return (
    <>
      <Activitys
        activityLabel="Atividades vinculadas ao gatilho"
        activitys={activitys}
        selectedActivitys={triggerActivitys}
        setSelectedActivitys={setTriggerActivitys}
      />
      <FormItem name="frequencie" label="Frequencia" item={<InputNumber value={frequencie} min={0} max={1000000000} onChange={(value) => setFrequencie(value)}/>} />
      
    </>
  );
}

export default memo(Presence);

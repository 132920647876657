import styled from 'styled-components';

export const Fixed = styled.div`
  height: 40px;
  width: 120px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

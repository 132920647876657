import React from 'react';
// Libraries
import { Link } from 'react-router-dom';
import { Divider, Typography, Input, Card } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
// Components
import Table from './components/Table';
import Button from '~/components/button';

const { Title } = Typography;
const { Search } = Input;

export default function MyIncomingRules() {
  return (
    <div>
      <Card>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Title level={4}>Regras de entrada</Title>
          </div>
          <div>
            <Search
              placeholder="Pesquisar regra"
              onSearch={(value) => console.log(value)}
              style={{ width: 200, marginRight: 10 }}
            />
            <Link to="/programas/programa/regras/regras-de-entrada/nova-regra">
              <Button
                text="Nova regra de entrada"
                icon={<PlusOutlined />}
                type="primary"
              />
            </Link>
          </div>
        </div>
        <Divider />
        <Table />
      </Card>
    </div>
  );
}

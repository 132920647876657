import React, { useContext } from 'react';
// Libraries
import { Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Table from './components/Table';
import Button from '~/components/button';
import Card from '~/components/card';
import Title from '~/components/typography/title';

import { HeaderContainer } from './style'

const { Search } = Input;

export default function Services() {
  
  const { theme } = useContext(ThemeContext);

  return (
 
      <Card>
        <HeaderContainer>
         
          <Title level={4}>Serviços</Title>
          
          <div>
            <Search
              placeholder="Pesquisar serviço"
              onSearch={(value) => console.log(value)}
              style={{ width: 200, marginRight: 10 }}
            />
            <Button
              text="Novo serviço"
              icon={<PlusOutlined />}
              type="primary"
            />
          </div>
        </HeaderContainer>
        <Divider style={{ borderColor: theme.color.white}} />
        <Table />
      </Card>
   
  );
}

import React, { memo, useState } from 'react';

import Input from '~/components/input';

import { Container } from './style';

function Text({ InputStyle, selectedRule }) {
  const [text, setText] = useState();

  console.log(`selectedRule`, selectedRule);

  return (
    <Container>
      {selectedRule === 'Está vazio' || selectedRule === 'Não está vazio' ? (
        <div />
      ) : (
        <Input
          placeholder="Digite o texto"
          style={InputStyle}
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
      )}
    </Container>
  );
}

export default memo(Text);

import React from 'react';
// Components
import { Link } from 'react-router-dom';
import { Badge } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import Button from '~/components/button';
import Table from '~/components/table';
import { ImageWithTextCell } from '~/components/table/cells';
// Assets
import image1 from '~/assets/images/icons/program1.svg';
import image2 from '~/assets/images/icons/program2.svg';
import image3 from '~/assets/images/icons/program3.svg';
import image4 from '~/assets/images/icons/program4.svg';

import { FlexStart } from './style'

export default function ProgramsTable() {
  // Variables
  const columns = [
    {
      title: 'Programa',
      dataIndex: 'program',
      key: 'program',
      render: (program, record) => (
        <ImageWithTextCell
          src={record.image}
          text={program}
          paddingLeft="120px"
        />
      ),
    },
    {
      title: 'Situação',
      dataIndex: 'situation',
      key: 'action',
      render: (situation) => (
        <span>
          <Badge
            status={situation === 'Programa Ativo' ? 'success' : 'warning'}
          />
          {situation}
        </span>
      ),
    },
    {
      title: 'Ações',
      dataIndex: 'address',
      key: 'address',
   
      render: () => (
        <FlexStart>
          <Link to="/programas/programa">
            <Button text="Mais informações" icon={<EyeOutlined />} />
          </Link>
        </FlexStart>
      ),
    },
  ];

  const data = [
    {
      key: '1',
      program: 'Carteira da Criança',
      image: image1,
      description: 'Está ativo há 2 anos e 3 meses',
      situation: 'Programa Ativo',
    },
    {
      key: '2',
      program: 'Inspira Boa Vista',
      image: image2,
      description: 'Está ativo há 2 meses',
      situation: 'Programa Ativo',
    },
    {
      key: '3',
      program: 'COOPCAS',
      image: image3,
      description: 'Ficou ativo por 3 anos, 2 meses e 13 dias',
      situation: 'Programa Suspenso',
    },
    {
      key: '4',
      program: 'Carteira da Criança',
      image: image4,
      description: 'Está ativo há 2 meses',
      situation: 'Programa Ativo',
    },
  ];
  return <Table pagination={false} tableColumns={columns} dataReceived={data} />;
}

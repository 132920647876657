import React, { useContext } from 'react';
//Contexts
import { AuthContext } from '~/contexts/AuthContext';
//Pages
import ManagerDescription from './ManagerDescription';
import ManagerDashboard from './ManagerDashboard';

export default function AuthenticationCheck() {
  const { user } = useContext(AuthContext);
  return user ? <ManagerDashboard /> : <ManagerDescription />;
}

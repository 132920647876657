import React from 'react';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';

export default function Home() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        padding: 20,
      }}
    >
      <div
        style={{
          width: '80%',
        }}
      >
        <div style={{ marginBottom: 20 }}>
          <img
            src="https://socialme-assets.s3.us-east-1.amazonaws.com/images/imageTeste2.png"
            alt="..."
            style={{ display: 'block', margin: '0px auto' }}
          />
        </div>
        <div>
          <Title level={4} textAlign="center">
            Vestibulum interdum id quam a semper. Praesent sagittis ullamcorper
            lobortis. Cras quis luctus felis, sed scelerisque erat. Nullam porta
            dolor augue, quis rutrum sem pulvinar a. Suspendisse finibus pretium
            ante, id tincidunt risus aliquam non. Praesent gravida risus vel est
            volutpat, et pretium dui viverra.
          </Title>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            margin: '20px auto',
            flexDirection: 'column',
            width: 200,
          }}
        >
          <a href="https://accounts.socialme.com.br">
            <Button
              type="primary"
              size="large"
              style={{ margin: '0px auto', display: 'block' }}
            >
              Entrar no Social Me
            </Button>
          </a>
          <a href="https://accounts.socialme.com.br">
            <Button type="link" style={{ marginTop: 10 }}>
              Criar uma conta Social Me
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
// Libraries
import { Form, Input, Button, Select, Radio } from 'antd';

const { Option } = Select;

export default function Information() {
  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      layout="vertical"
      size="default"
      onFinish={onFinish}
      scrollToFirstError
      initialValues={{
        type: 'Governamental',
      }}
    >
      <Form.Item name={['sexo']} label="Sexo">
        <Radio.Group>
          <Radio value={1}>Homem</Radio>
          <Radio value={2}>Mulher</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Idade">
        <Form.Item name="idade_exatamente">
          <Input
            addonBefore="Exatamente"
            addonAfter="anos"
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item name="idade_maior_que">
          <Input
            addonBefore="Maior que"
            addonAfter="anos"
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item name="idade_menor_que">
          <Input
            addonBefore="Menor que"
            addonAfter="anos"
            style={{ width: 250 }}
          />
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <Form.Item name="idade_entre_1">
            <Input addonBefore="Entre" style={{ width: 110 }} />
          </Form.Item>
          <Form.Item name="idade_entre_2">
            <Input addonBefore="e" addonAfter="anos" style={{ width: 140 }} />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item name={['nacionalidade']} label="Nacionalidade">
        <Select style={{ width: 250 }}>
          <Option value="Nacionalidade 1">Nacionalidade 1</Option>
          <Option value="Nacionalidade 2">Nacionalidade 2</Option>
          <Option value="Nacionalidade 3">Nacionalidade 3</Option>
        </Select>
      </Form.Item>

      <Form.Item name={['condicao']} label="Condição de Saúde">
        <Select style={{ width: 250 }}>
          <Option value="Condição 1">Condição 1</Option>
          <Option value="Condição 2">Condição 2</Option>
          <Option value="Condição 3">Condição 3</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Renda Familiar">
        <Form.Item name="renda_exatamente">
          <Input
            addonBefore="Exatamente"
            addonAfter="reais"
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item name="renda_maior_que">
          <Input
            addonBefore="Maior que"
            addonAfter="reais"
            style={{ width: 250 }}
          />
        </Form.Item>
        <Form.Item name="renda_menor_que">
          <Input
            addonBefore="Menor que"
            addonAfter="reais"
            style={{ width: 250 }}
          />
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <Form.Item name="renda_entre_1">
            <Input addonBefore="Entre" style={{ width: 110 }} />
          </Form.Item>
          <Form.Item name="renda_entre_2">
            <Input addonBefore="e" addonAfter="reais" style={{ width: 140 }} />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Adicionar regra
        </Button>
      </Form.Item>
    </Form>
  );
}

import React, { useContext } from 'react';

import { Row, Col } from 'antd';

import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';

import Affix from '~/components/affix';
import Drawer from '~/components/drawer';

import Header from './components/Header';
import Menu from './components/Menu';
import Content from './components/Content';

import { Fixed } from './style';

export default function ShowProgram() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <>
      <Row>
        <Col span={24}>
          <Header />
        </Col>
      </Row>
      <Row>
        <Col sxl={5} lg={5} md={5} xs={0} sm={0}>
          <Menu theme={theme} />
        </Col>

        <Col xl={19} lg={19} md={19} xs={24} sm={24}>
          <Content theme={theme} />
        </Col>
      </Row>
      {isMobile && (
        <Drawer
          placement="left"
          title="Adiministração do programa"
          width={isMobile ? '100vw' : '70vw'}
          Trigger={
            <Affix offsetBottom={15}>
              <Fixed
                color={theme.color.white}
                backgroundColor={theme.color.primary}
              >
                Menu
              </Fixed>
            </Affix>
          }
          DrawerContentComponent={Menu}
        />
      )}
    </>
  );
}
